import React, {useState, useEffect, useContext} from 'react';
import styled from "styled-components"
import {RoomContext} from "../../../context/RoomContext";
import {OrganizationContext} from "../../../context/OrganizationContext";
import GameWrapper from "../../atoms/GameWrapper";
import Timer from "../../molecules/TimeBar";
import TeamHeader from "../../molecules/TeamHeader";
import Button from "../../atoms/Button";

const DiscussionPage = ({round, syncPageSwitch}) => {

    let {roomData} = useContext(RoomContext)
    let {organizationData} = useContext(OrganizationContext)

    //state to save id of interval to clear it later on
    const [intervalID, setIntervalID] = useState(0)

    //state for question one
    const [questionOne, setQuestionOne] = useState("")

    //state for question two
    const [questionTwo, setQuestionTwo] = useState("")

    //state for handling displaying the next page
    const [showNextButton, setShowNextButton] = useState(false)


    //state for current question (1 === first question, 2 === second question)
    const [questionNo, setQuestionNo] = useState(1)

    const handleSyncNextPage = () => {
        intervalID && clearInterval(intervalID)
        syncPageSwitch()
    }

    useEffect(() => {

        //switch case for setting the right questions depending on which game round it is
        switch (round) {
            case 1:
                setQuestionOne(organizationData.questionOneDiscussionFirstAssignment)
                setQuestionTwo(organizationData.questionTwoDiscussionFirstAssignment)
                break
            case 2:
                setQuestionOne(organizationData.questionOneDiscussionSecondAssignment)
                setQuestionTwo(organizationData.questionTwoDiscussionSecondAssignment)
                break
            case 3:
                setQuestionOne(organizationData.questionOneDiscussionThirdAssignment)
                setQuestionTwo(organizationData.questionTwoDiscussionThirdAssignment)
                break

        }

    }, [])

    //gets seconds passed
    const handleProgress = (secondsPassed) => {
        //if 90 seconds are past, change the question default 90
        if (secondsPassed === 90) {
            setQuestionNo(2)
        }
        //if 120 seconds are past, show the jump discussion button default 120
        if (secondsPassed === 120) {
            setShowNextButton(true)
        }
        //if 180 seconds down, next page default 180
        if (secondsPassed === 180) {
            handleSyncNextPage()
        }
    }

    //returns the requested question depending on questionNo state
    const getQuestion = () => {
        if (questionNo === 1) {
            return (
                <Question>{`${roomData.discussionNames?.length > 0 ? roomData.discussionNames[0].userName : "USER"},  ${questionOne}`}</Question>

            )
        } else {
            return (
                <Question>{`${roomData.discussionNames?.length > 0 ? roomData.discussionNames[1].userName : "USER"},  ${questionTwo}`}</Question>
            )
        }
    }

    return (
        
        <GameWrapper>
            <TeamHeader/>
            <Header>Groepsdiscussie vraag {`${questionNo}/2`}</Header>
            {getQuestion()}
            <TimerWrapper>
                <Timer timeInSeconds={180} onProgress={(timeLeft) => handleProgress(timeLeft)}
                       setInterID={(id) => setIntervalID(id)}/>
            </TimerWrapper>
            {showNextButton && (
                <EndDiscussionButtonWrapper>
                    <Button text={"Klaar met de discussie?"} handleClick={() => handleSyncNextPage()}/>


                </EndDiscussionButtonWrapper>

            )}
        </GameWrapper>
    );
}


export default DiscussionPage;


const EndDiscussionButtonWrapper = styled.div`
  & button {
    position: absolute;
    left: 50%;
    bottom: 80px;
    transform: translateX(-50%);
  }
`

const Question = styled.p`
  width: 60%;
  color: white;
  margin: 80px auto;
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
`


const Header = styled.h1`
  color: white;
  font-size: 2.5rem;
  margin-left: 40px;
`

const TimerWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`
