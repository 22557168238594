import React from 'react';
import styled from "styled-components"
import Image from "../../public/images/background.jpg"

const GameWrapper = (props) => {
    return (
        <Wrapper>
            {props.children}
        </Wrapper>
    );
}

export default GameWrapper;


const Wrapper = styled.div`
  background: url(${Image}) no-repeat center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  max-height: 100vh;
  overflow: hidden;
  position: relative;
`












