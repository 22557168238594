import React from "react";
import ReactDOM from "react-dom";
import theme from "./styling/theme";
import {ThemeProvider} from "styled-components";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Global from "./styling/global";
import Context from "./context/";
import reportWebVitals from "./reportWebVitals";
import Landing from "./components/Landing/Landing";
import Join from "./components/Join/Join";
import Game from "./components/Game/index"
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TeamLogin from "./components/TeamOverview/login"
import TeamOverview from "./components/TeamOverview/overview"
import Loader from "./components/molecules/Loader";
import App from "./App"
import ReactGA from "react-ga";
import styled from "styled-components"

const ToastWrapper = styled.div`
  & .Toastify__toast--success {
    background-color: white;
    color: black;
  }

`

ReactGA.initialize('UA-186034118-1', {});
ReactDOM.render(
    <React.StrictMode>
        <Router>
            <ThemeProvider theme={theme}>
                <Global/>
                <Context>
                    <ToastWrapper>
                        <ToastContainer
                        />
                    </ToastWrapper>
                    <Loader/>
                    <App>
                        <Switch>
                            <Route path={"/"} exact component={Landing}/>
                            <Route path={"/team/login"} exact component={TeamLogin}/>
                            <Route path={"/team/"} exact component={TeamOverview}/>
                            <Route path={"/join/:orgCode/:roomCode"} component={Join}/>
                            <Route path={"/game/:orgCode/:roomCode"} component={Game}/>
                        </Switch>
                    </App>
                </Context>
            </ThemeProvider>
        </Router>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



