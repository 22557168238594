import React, {useContext, useState} from 'react';
import styled from "styled-components"
import {RoomContext} from "../../../context/RoomContext";
import {OrganizationContext} from "../../../context/OrganizationContext";
import TeamHeader from "../../molecules/TeamHeader";
import GameWrapper from "../../atoms/GameWrapper";
import JudithImage from "../../../public/images/judith.png"
import BellaImage from "../../../public/images/bella.png"
import LexImage from "../../../public/images/daan.png"
import Player from "../../molecules/Player";
import {MEDIA_URL} from "../../../utils/constants";
import Button from "../../atoms/Button"

const HackerPage = ({round, forwardPage}) => {

    let {organizationData} = useContext(OrganizationContext)
    let {roomData, changeRoomData, nextPage} = useContext(RoomContext)

    //handles which hack is shown or if "homepage" is shown
    let [activeHack, setActiveHack] = useState(null)


    //sets active hacker
    const handleHackerClick = (hackerName) => {
        setActiveHack(hackerName)
    }

    //checks if specific hack is selected
    const isHackerVideo = () => {
        return activeHack === "lex" || activeHack === "judith" || activeHack === "bella";
    }

    //handles back to homepage click
    const handleBackToOverview = () => {
        setActiveHack(null)
    }


    const imageHackPage = ({videoLink, imageLink}) => {
        return (
            <GameWrapper>
                <HackWrapper>
                    <HackerImageGrid>
                        <HackImageWrapper>
                            <HackImage src={MEDIA_URL + imageLink}/>
                        </HackImageWrapper>
                        <HackInfoWrapper>
                            <HackInfoTopic>
                                {activeHack === "lex" ? organizationData.titleThirdHackLex : activeHack === "judith" ? organizationData.titleThirdHackJudith : organizationData.titleThirdHackBella}
                            </HackInfoTopic>
                            <HackInfoName>
                                {activeHack.charAt(0).toUpperCase() + activeHack.slice(1)}
                            </HackInfoName>
                        </HackInfoWrapper>
                    </HackerImageGrid>
                    <Player url={videoLink}/>
                    <BackButtonWrapper>
                        <Button text={"Terug"} handleClick={() => handleBackToOverview()}/>
                    </BackButtonWrapper>
                </HackWrapper>
            </GameWrapper>
        )
    }


    const videoHackerPage = (videoLink) => {
        return (
            <GameWrapper>
                <HackWrapper>
                    <Player url={videoLink}/>
                    <BackButtonWrapper>
                        <Button text={"Terug"} handleClick={() => handleBackToOverview()}/>
                    </BackButtonWrapper>
                </HackWrapper>
            </GameWrapper>
        )
    }


    // handles page change
    const handleDoneWatching = () => {
        forwardPage()
    }

    const getPageContent = () => {
        if (activeHack === null) {
            return (
                <GameWrapper>
                    <TeamHeader/>
                    <HackWrapper>
                        <HackTopic>{round === 1 ? organizationData.titleFirstAssignment : round === 2 ? organizationData.titleSecondAssignment : organizationData.titleThirdAssignment}</HackTopic>
                        <HackersWrapper>
                            <SingleHackerWrapper>
                                <SingleHackerImage src={LexImage}/>
                                <SingleHackerButton onClick={() => handleHackerClick("lex")}>bekijk hack
                                    Lex</SingleHackerButton>
                            </SingleHackerWrapper>
                            <SingleHackerWrapper>
                                <SingleHackerImage src={JudithImage}/>
                                <SingleHackerButton onClick={() => handleHackerClick("judith")}>bekijk hack
                                    Judith</SingleHackerButton>
                            </SingleHackerWrapper>
                            <SingleHackerWrapper>
                                <SingleHackerImage src={BellaImage}/>
                                <SingleHackerButton onClick={() => handleHackerClick("bella")}>bekijk hack
                                    Bella</SingleHackerButton>
                            </SingleHackerWrapper>
                        </HackersWrapper>

                        <DoneButtonWrapper>
                            <Button handleClick={handleDoneWatching}
                                    text={"Alle hacks bekeken? Start de beoordeling!"}/>
                        </DoneButtonWrapper>
                    </HackWrapper>
                </GameWrapper>
            );
        }

        // handles which media to show depending on what round we're in
        if (isHackerVideo()) {
            let {
                videoFirstHackLex,
                videoFirstHackJudith,
                videoFirstHackBella,


                videoSecondHackLex,
                videoSecondHackJudith,
                videoSecondHackBella,


                videoThirdHackLex,
                videoThirdHackJudith,
                videoThirdHackBella,


                imageThirdHackLex,
                imageThirdHackJudith,
                imageThirdHackBella

            } = organizationData


            if (round === 1) {
                return videoHackerPage(activeHack === "lex" ? videoFirstHackLex : activeHack === "judith" ? videoFirstHackJudith : videoFirstHackBella)
            }
            if (round === 2) {
                return videoHackerPage(activeHack === "lex" ? videoSecondHackLex : activeHack === "judith" ? videoSecondHackJudith : videoSecondHackBella)
            }


            //HACK LET PEOPLE UPLOAD AN IMAGE FOR
            if (round === 3) {
                return imageHackPage(activeHack === "lex" ? {
                    videoLink: videoThirdHackLex,
                    imageLink: imageThirdHackLex
                } : activeHack === "judith" ? {
                    videoLink: videoThirdHackJudith,
                    imageLink: imageThirdHackJudith
                } : {
                    videoLink: videoThirdHackBella,
                    imageLink: imageThirdHackBella
                })
            }
        }
    }

    return (
        getPageContent()
    )


}


export default HackerPage

const HackInfoTopic = styled.h1`
  letter-spacing: .1rem;
  font-size: 2.8rem;
  text-align: center;
  @media (max-width: 880px) {
    font-size: 2.2rem;
  }
  @media (max-width: 760px) {
    font-size: 1.7rem;
  }

  @media (max-width: 460px) {
    font-size: 1.2rem;
  }

`

const HackInfoName = styled.h2`
  font-weight: 400;

  @media (max-width: 880px) {
    font-size: 1.6rem;
  }
  @media (max-width: 760px) {
    font-size: 1.2rem;
  }

  @media (max-width: 460px) {
    font-size: 1rem;
  }
`


const HackInfoWrapper = styled.div`
  grid-column: 2/3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
`

const HackerImageGrid = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const HackImage = styled.img`
  height: 65vh;
  width: auto;
  margin-left: 40px;
  @media (max-height: 700px) {
    height: 60vh;
  }
`

const HackImageWrapper = styled.div`
  grid-column: 1/2;
  display: flex;
  justify-content: flex-start;
  align-items: center;

`

const DoneButtonWrapper = styled.div`
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);


  & button {
    @media (max-height: 760px) {
      font-size: 16px;

    }

    margin: 0;
  }
`


const BackButtonWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  left: 40px;

  & button {
    margin: 0;
  }

`

const HackWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const HackTopic = styled.h1`
  color: white;
  margin: 40px auto;
`

const HackersWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`


const SingleHackerWrapper = styled.div`
  margin: 40px 20px;
  display: flex;
  flex-direction: column;
`

const SingleHackerImage = styled.img`
  width: auto;
  height: 274px;
  border-radius: 8px;
  max-width: 256px;
  @media (max-height: 760px) {
    width: auto;
    height: 158px;
  }
`


const SingleHackerButton = styled.button`
  padding: 12px 16px;
  margin-top: 24px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px;
  border: none;

`