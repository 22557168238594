import React, {useContext} from 'react';
import styled from "styled-components"
import VotingBackground from "../../../public/images/votingbackground.jpeg"
import {onDragOver, onDrop, onDragStart, onSwitch} from "../../../utils/votingSystem"
import Lex from "../../../public/images/daan.png"
import Judith from "../../../public/images/judith.png"
import Bella from "../../../public/images/bella.png"
import {OrganizationContext} from "../../../context/OrganizationContext";
import {RoomContext} from "../../../context/RoomContext";
import Button from "../../atoms/Button"


const Voting = ({round, voteComplete, forwardPage}) => {

    const {organizationData} = useContext(OrganizationContext)


    const submitVoting = () => {

        const first = document.getElementById("firstplace").title;
        const second = document.getElementById("secondplace").title;
        const third = document.getElementById("lastplace").title;


        let votingLex1 = round === 1 ? organizationData.votingOneLex1 : round === 2 ? organizationData.votingTwoLex1 : organizationData.votingThreeLex1;
        let votingLex2 = round === 1 ? organizationData.votingOneLex2 : round === 2 ? organizationData.votingTwoLex2 : organizationData.votingThreeLex2;
        let votingLex3 = round === 1 ? organizationData.votingOneLex3 : round === 2 ? organizationData.votingTwoLex3 : organizationData.votingThreeLex3;


        let votingJudith1 = round === 1 ? organizationData.votingOneJudith1 : round === 2 ? organizationData.votingTwoJudith1 : organizationData.votingThreeJudith1;
        let votingJudith2 = round === 1 ? organizationData.votingOneJudith2 : round === 2 ? organizationData.votingTwoJudith2 : organizationData.votingThreeJudith2;
        let votingJudith3 = round === 1 ? organizationData.votingOneJudith3 : round === 2 ? organizationData.votingTwoJudith3 : organizationData.votingThreeJudith3;


        let votingBella1 = round === 1 ? organizationData.votingOneBella1 : round === 2 ? organizationData.votingTwoBella1 : organizationData.votingThreeBella1
        let votingBella2 = round === 1 ? organizationData.votingOneBella2 : round === 2 ? organizationData.votingTwoBella2 : organizationData.votingThreeBella2
        let votingBella3 = round === 1 ? organizationData.votingOneBella3 : round === 2 ? organizationData.votingTwoBella3 : organizationData.votingThreeBella3


        let score1 = 0
        let score2 = 0
        let score3 = 0
        let error = 0


        switch (first) {
            case "lex":
                score1 = votingLex1
                break
            case 'judith':
                score1 = votingJudith1
                break;
            case 'bella':
                score1 = votingBella1
                break;
            default:
                error = 1
                break;
        }
        switch (second) {
            case "lex":
                score2 = votingLex2
                break;
            case 'judith':
                score2 = votingJudith2
                break;
            case 'bella':
                score2 = votingBella2
                break;
            default:
                error = 1
                break;
        }
        switch (third) {
            case "lex":
                score3 = votingLex3
                break;
            case 'judith':
                score3 = votingJudith3
                break;
            case 'bella':
                score3 = votingBella3
                break;
            default:
                error = 1
                break;
        }
        if (error > 0) {
            return;
        }

        let score = score1 + score2 + score3
        voteComplete(round, score)
        forwardPage()

        
    }


    return (
        <Wrapper>
            <OuterContainer>
                <InnerContainer>
                    <h1>Welke amateur hacker is volgens jou het beste?</h1>
                    <h2>Sleep de hacker op de juiste positie</h2>

                    <form>
                        <GridContainer>
                            <div>
                                <CardPlacement>
                                    <FirstPlacing id="firstplace" title="0" onDragOver={onDragOver} onDrop={onDrop}>

                                    </FirstPlacing>
                                </CardPlacement>
                            </div>
                            <div>
                                <CardPlacement>
                                    <SecondPlacing id="secondplace" title="0" onDragOver={onDragOver} onDrop={onDrop}>

                                    </SecondPlacing>
                                </CardPlacement>
                            </div>
                            <div>
                                <CardPlacement>
                                    <ThirdPlacing id="lastplace" title="0" onDragOver={onDragOver} onDrop={onDrop}>

                                    </ThirdPlacing>
                                </CardPlacement>
                            </div>
                            <div>
                                <LexPlacing id="parentlex">
                                    <ScoringCard name="lex" title="" id="drag1" draggable="true"
                                                 onDragStart={onDragStart} onDragOver={onDragOver} onDrop={onSwitch}>
                                        <ScoringCardInfo>
                                            <ScoringCardInfoText>
                                                {round === 1 ? organizationData.votingOneLexCardTitle : round === 2 ? organizationData.votingTwoLexCardTitle : organizationData.votingThreeLexCardTitle}
                                            </ScoringCardInfoText>
                                        </ScoringCardInfo>
                                        <ScoringCardImage src={Lex} draggable="false" alt="Hack Lex">
                                        </ScoringCardImage>
                                    </ScoringCard>
                                </LexPlacing>
                            </div>
                            <div>
                                <JudithPlacing id="parentjudith">
                                    <ScoringCard name="judith" title="" id="drag2" draggable="true"
                                                 onDragStart={onDragStart} onDragOver={onDragOver} onDrop={onSwitch}>
                                        <ScoringCardInfo>
                                            <ScoringCardInfoText>
                                                {round === 1 ? organizationData.votingOneJudithCardTitle : round === 2 ? organizationData.votingTwoJudithCardTitle : organizationData.votingThreeJudithCardTitle}
                                            </ScoringCardInfoText>
                                        </ScoringCardInfo>
                                        <ScoringCardImage src={Judith} draggable="false" alt="Hack Judith">
                                        </ScoringCardImage>
                                    </ScoringCard>
                                </JudithPlacing>
                            </div>
                            <div>
                                <BellaPlacing id="parentBella">
                                    <ScoringCard name="bella" title="" id="drag3" draggable="true"
                                                 onDragStart={onDragStart} onDragOver={onDragOver} onDrop={onSwitch}>
                                        <ScoringCardInfo>
                                            <ScoringCardInfoText>
                                                {round === 1 ? organizationData.votingOneBellaCardTitle : round === 2 ? organizationData.votingTwoBellaCardTitle : organizationData.votingThreeBellaCardTitle}
                                            </ScoringCardInfoText>
                                        </ScoringCardInfo>
                                        <ScoringCardImage src={Bella} draggable="true" alt="Hack Bella">
                                        </ScoringCardImage>
                                    </ScoringCard>
                                </BellaPlacing>
                            </div>
                        </GridContainer>
                    </form>
                    <ButtonWrapper>
                        <Button text={"Bevestig je beoordeling"}
                                handleClick={() => submitVoting()}/>
                    </ButtonWrapper>
                </InnerContainer>
            </OuterContainer>
        </Wrapper>
    );
}


export default Voting


const ButtonWrapper = styled.div`
  & button {
    margin: 0;
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
  }
`

const ScoringCardImage = styled.img`
  height: 24vh;
  width: 20vh;
  pointer-events: none;
`

const ScoringCardInfoText = styled.p`
  font-family: "Montserrat", sans-serif;
  font-size: .8rem;
  font-weight: 400;
  pointer-events: none;
`
const ScoringCardInfo = styled.div`
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, .6);
  pointer-events: none;
`

const ScoringCard = styled.div`
  cursor: move;
  position: relative;
  height: 24vh;
  width: 20vh;
`

const ScoringCardWrapper = styled.div`

`

const LexPlacing = styled(ScoringCardWrapper)`
  position: absolute;
  left: 7%;
  top: 5%;
`

const JudithPlacing = styled.div`
  position: absolute;
  left: 7%;
  top: 32.5%;
`

const BellaPlacing = styled.div`
  position: absolute;
  left: 7%;
  top: 60%;
`

const FirstPlacing = styled.div`
  border-radius: 5px;
  text-align: center;
  font-weight: 400;
  font-size: .8rem;
  border: 1px solid #000;
  height: 24vh;
  width: 20vh;
  overflow: hidden;
  position: absolute;
  left: 44.5%;
  top: 30.5%;
`


const SecondPlacing = styled(FirstPlacing)`
  left: 30%;
  top: 43%;
`

const ThirdPlacing = styled(FirstPlacing)`
  left: 59%;
  top: 49.5%;

`

const CardPlacement = styled.div`
  text-align: center;
`


const GridContainer = styled.div`

  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
  bottom: 4rem;
  margin-top: 40px;
`
const InnerContainer = styled.div`
  display: inline-block;
  text-align: center;
  width: 40%;

  & h1, h2 {
    color: white;
    text-shadow: -2px 2px 4px #000;
  }
`

const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 4rem;
`

const Wrapper = styled.div`
  background: url(${VotingBackground}) no-repeat center;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`


