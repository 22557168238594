import {io} from 'socket.io-client';
import {SOCKET_HOST} from "../utils/constants";

class SocketManager {
    //holds the io object
    constructor() {
        this._socket = null
    }

    createSocketAndJoinRoom(roomCode, userName, userEmail, orgCode) {
        if (!this._socket) {
            this._socket = io(SOCKET_HOST)
        }

        this._socket.emit("join", ({roomCode, userName, userEmail, orgCode}))

        return this._socket
    }
    
    isConnected() {
        if (this._socket) {
            return this._socket.connected
        }
        return false
    }
}

export default SocketManager


