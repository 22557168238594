import {getCompany} from "../axios";
import {toast} from "react-toastify";

export const companyValidator = (orgCode, history, successCallback, errorCallback) => {
    return new Promise((resolve, reject) => {
        getCompany(orgCode)
            .then(result => {
                if (result.success) {
                    resolve(result.organizationData)
                    return
                }
                reject()
                toast.error("Bedrijf niet gevonden, probeer het nog eens.")
                history.push("/")
            }).catch(err => {
            console.error(err)
            reject(err)
            toast.error("Fout bij het ophalen van gegevens, probeer het nog eens.")
            history.push("/")
        })
    })
}