const onDragStart = (event) => {
    event.dataTransfer.setData('text', event.target.id);
}

const onDragOver = (event) => {
    event.preventDefault();
}

const onDrop = (event) => {
    event.preventDefault()
    const data = event.dataTransfer.getData("text");
    const drag = document.getElementById(data)
    if (drag) {
        if (drag.id === 'drag1' || drag.id === 'drag2' || drag.id === 'drag3') {
            event.target.title = document.getElementById(data).getAttribute("name");
            document.getElementById(data).setAttribute("title", event.target.id)
            event.target.appendChild(drag);
        }
    }
}

const replaceElements = (e, drag, previmage, parentName) => {
    let oldElement = e.target


    //grapping old placingwrapper
    let oldPlacingWrapper = document.querySelector(`[title=${drag.getAttribute("name")}]`)

    //in case you replace a spot, it will be set to 0
    if (oldPlacingWrapper) {
        oldPlacingWrapper.setAttribute("title", "0")
    }

    //taking the placing wrapper of the individual ranking and and give it the title of the name of the new element
    let placingWrapper = document.getElementById(e.target.title)
    placingWrapper.setAttribute("title", drag.getAttribute("name"))

    //the origin of the old Element and append it to that
    let placeOfOrigin = document.getElementById(parentName)
    placeOfOrigin.append(oldElement)


    //appending the new drag stuff to the placing wrapper
    placingWrapper.append(drag)

    //taking the new placed element and set title to the according placement
    drag.setAttribute("title", placingWrapper.getAttribute("id"))

    //taking the old element and setting the attribute title to ""
    document.getElementById(previmage).setAttribute("title", "")

}

const onSwitch = (e) => {
    e.preventDefault();
    const data = e.dataTransfer.getData("text");
    const drag = document.getElementById(data)
    const previmage = e.target.id

    if (drag) {
        if (e.target.title) {
            if (previmage === "drag1") {
                replaceElements(e, drag, previmage, "parentlex")
            }
            if (previmage === "drag2") {
                replaceElements(e, drag, previmage, "parentjudith")
            }
            if (previmage === "drag3") {
                replaceElements(e, drag, previmage, "parentBella")
            }
        }
    }
}
export {onDragStart, onDragOver, onDrop, onSwitch};