import Cookies from 'universal-cookie';

let cookie = new Cookies();

export const getCookieData = (name) => {

    if (typeof name === 'string' && name.length > 0) {
        let data = cookie.get(name, {path: '/'})

        if (data) {
            return data
        }
        return null
    }
}


export const setCookieData = (name, data) => {
    cookie.set(name, data, {path: '/'});
    return data
}


export const resetCookie = (name) => {
    console.log("cookie removed")
    cookie.remove(name, {path: '/'});

    return true
}