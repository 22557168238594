import React, {useContext, useRef, useState} from 'react';
import GameWrapper from "../atoms/GameWrapper";
import styled from "styled-components";
import Copy from "../../public/icons/copy.svg";
import {toast} from "react-toastify";
import {RoomContext} from "../../context/RoomContext";
import {useHistory, useParams} from "react-router-dom";
import InputField from "../atoms/InputField";
import Check from "../../public/icons/check.svg";
import Cancel from "../../public/icons/cancel.svg";
import {HOST, MEDIA_URL} from "../../utils/constants";
import {OrganizationContext} from "../../context/OrganizationContext";

const WaitingRoom = (props) => {

    let copyRef = useRef()
    let history = useHistory()
    let {orgCode, roomCode} = useParams()
    let {roomData, changeRoomData} = useContext(RoomContext)
    let {organizationData} = useContext(OrganizationContext)
    

    const [teamName, setTeamName] = useState("")
    const [teamPassword, setTeamPassword] = useState("")
    const [buttonReady, setButtonReady] = useState("")
    const handleCopyLink = () => {
        copyRef.current.select();
        document.execCommand('copy');
        toast.success("Link naar klembord gekopieerd")
    }

    const handleStartButtonClicked = () => {
        props.handleStartButtonClicked()
    }


    const handleTeamNameInout = value => {
        setTeamName(value)
        if (teamPassword.length > 0 && value.length > 0) {
            setButtonReady(true)
            return
        }
        setButtonReady(false)
    }


    const handleTeamPasswordInput = value => {
        setTeamPassword(value)
        if (teamName.length > 0 && value.length > 0) {
            setButtonReady(true)
            return
        }
        setButtonReady(false)
    }


    const handleCreateTeamButton = () => {
        if (teamName.length > 0 && teamPassword.length > 0 && buttonReady) {
            let roomCode = roomData.roomCode
            props.leaveWaitingRoom()
            props.startGame(teamName, teamPassword, roomCode)
        }
    }


    const handleCloseTeamButton = () => {
        props.handleCancelTeamButtonClicked()
    }
    
    return (
        <GameWrapper>
            {roomData.currentPage === "waitForTeam" ?
                <ModalBackground>
                    <ModalWrapper>
                        <InputHeader>Verzin gezamenlijk een teamnaam en wachtwoord</InputHeader>
                        <InputSubheader>{roomData.teamAdmin ? roomData.teamAdmin : "Een collega"} maakt het teamaccount
                            aan</InputSubheader>
                    </ModalWrapper>
                </ModalBackground>
                : roomData.currentPage === "setTeamName" ?
                    <ModalBackground>
                        <ModalWrapper>
                            <InputHeader>Verzin gezamenlijk een teamnaam en wachtwoord</InputHeader>
                            <InputSubheader>Overleg eerst met je team</InputSubheader>
                            <InputWrapper>
                                <InputField value={teamName} textAlign={"center"}
                                            handleInput={value => handleTeamNameInout(value)}
                                            placeholder={"Teamnaam"}/>
                                <InputField type={"password"} value={teamPassword} textAlign={"center"}
                                            handleInput={value => handleTeamPasswordInput(value)}
                                            placeholder={"Wachtwoord"}/>
                            </InputWrapper>
                            <ButtonWrapper>

                                <CancelButton onClick={() => handleCloseTeamButton()} ready={buttonReady}/>
                                <ContinueButton onClick={() => handleCreateTeamButton()} ready={buttonReady}/>
                            </ButtonWrapper>
                        </ModalWrapper>
                    </ModalBackground>
                    : null}

            <Wrapper>

                <InfoWrapper>
                    {/*<p onClick={() => sm.test()}>GAME</p>*/}
                    <CompanyName>{organizationData.orgName}</CompanyName>
                    <CompanyLogoWrapper>
                        <CompanyLogo src={MEDIA_URL + organizationData.logo}></CompanyLogo>
                    </CompanyLogoWrapper>
                    <ShareCopy>Deel de onderstaande link met je teamgenoten zodat ze kunnen deelnemen aan je sessie:
                    </ShareCopy>
                    <ShareWrapper>
                        <ShareLink readOnly value={HOST + history.location.pathname}
                                   ref={copyRef}/>
                        {document.queryCommandSupported('copy') &&
                        <CopyIconWrapper onClick={() => handleCopyLink()}/>}
                    </ShareWrapper>

                    <AdditionalInfoWrapper>
                        <AdditionalInfoHeader>Lees deze belangrijke informatie</AdditionalInfoHeader>
                        <AdditionalInfoList>
                            <AdditionalInfoListItem>Zet nu je hoofdtelefoon op. Geen hoofdtelefoon? Zet je microfoon uit
                                wanneer je niet spreekt</AdditionalInfoListItem>
                            <AdditionalInfoListItem>Gebruik enkel de knoppen binnen de pagina, gebruik niet de vorige
                                pagina-knop in Chrome
                            </AdditionalInfoListItem>
                            <AdditionalInfoListItem>Loopt je browser vast? Sluit Chrome af en start de sessie opnieuw
                                met de uitnodigingslink</AdditionalInfoListItem>
                        </AdditionalInfoList>
                    </AdditionalInfoWrapper>

                </InfoWrapper>
                <PlayerWrapper>
                    <PlayerHeaderWrapper>
                        <PlayerHeader>Deelnemers aanwezig
                            ({roomData.users ? roomData.users.length : 0}/5):</PlayerHeader>
                    </PlayerHeaderWrapper>

                    <PlayerListWrapper>

                        {roomData.users && roomData.users.map(user => {
                            return (
                                <PlayerName key={user.userID}>{user.userName}</PlayerName>
                            )
                        })}
                    </PlayerListWrapper>

                    <StartButtonWrapper>
                        <StartGameButton onClick={() => handleStartButtonClicked()}>
                            Iedereen aanwezig? Start Heel Holland Hackt!

                        </StartGameButton>
                    </StartButtonWrapper>

                </PlayerWrapper>
            </Wrapper>
        </GameWrapper>
    );
}


export default WaitingRoom;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ContinueButton = styled.div`
  background: url(${Check}) ${props => props.ready ? "white" : "gray"} center no-repeat;
  background-size: 34px;
  height: 48px;
  width: 48px;
  margin: 40px 12px 18px 12px;
  border-radius: 50px;
  cursor: ${props => props.ready ? "pointer" : "default"};


  &:active {
    box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
  }
`

const CancelButton = styled(ContinueButton)`
  background-image: url(${Cancel});
  background-color: white;
`


const InputHeader = styled.h2`
  color: white;
  text-align: center;
`
const InputSubheader = styled.h3`
  color: white;
  font-weight: 400;
  text-align: center;
  margin-bottom: 38px;
`
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  & div:first-child {
    margin-bottom: 20px;
  }
`

const ModalBackground = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalWrapper = styled.div`
  width: 48vw;
  border-radius: 10px;
  padding: 12px;
  background: black;
  border: 2px solid white;
`


const PlayerName = styled.p`
  font-size: 24px;
  font-weight: 500;
  color: white;
  text-align: center;
`

const StartGameButton = styled.button`
  margin-top: 40px;
  height: 60px;
  background-color: white;
  font-size: 18px;
  font-weight: 600;
  outline: none;
  border: 2px white solid;
  border-radius: 10px;
  padding: 12px 18px;
  cursor: pointer;

  &:hover {
    border: 2px ${props => props.theme.colors.warnRed} solid;
  }


  white-space: normal;
  word-wrap: break-word
`
const StartButtonWrapper = styled.div`
grid-column: 1;
grid-row: 3/4;
display: flex;
justify-content: center;
align-items: flex-start;
`


const PlayerListWrapper = styled.div`
grid-column: 1;
grid-row: 2/3;

`


const PlayerHeader = styled.h2`
color: white;
`
const PlayerHeaderWrapper = styled.div`
grid-column: 1;
grid-row: 1/2;

display: flex;
justify-content: center;
align-items: flex-end;
`


const AdditionalInfoListItem = styled.li`
padding: 8px 0px;
margin-left: 0;
`

const AdditionalInfoList = styled.ul`
color: white;
padding-left: 14px;
`

const AdditionalInfoHeader = styled.h2`
color: ${props => props.theme.colors.warnRed};

  @media (max-height: 840px) {
    font-size: 22px;
  }


`
const AdditionalInfoWrapper = styled.div`
  margin: 30px 80px 0;

  @media (max-height: 840px) {
    margin: 10px 80px 0;
  }
`


const CopyIconWrapper = styled.div`
background: url(${Copy}) transparent center no-repeat;
background-size: 28px;
height: 48px;
width: 48px;
cursor: pointer;
&:focus{
background-color: darkgray;
}
`
const ShareLink = styled.input`
min-width: 250px;
background: none;
outline: none;
box-shadow: none;
border: none;
font-size: 14px;
`
const ShareWrapper = styled.div`
padding-left: 16px;
background: lightgrey;
height: 48px;
display: flex;
border-radius: 12px;

`

const ShareCopy = styled.p`
  margin: 50px 80px 20px;
  text-align: center;
  font-size: 22px;
  color: white;
  @media (max-height: 840px) {
    font-size: 18px;
  }
`

const CompanyName = styled.h1`
color: white;
`
const CompanyLogoWrapper = styled.div`
  height: 150px;
  @media (max-height: 820px) {
    height: 118px;
  }
`

const CompanyLogo = styled.img`
max-height: 150px;
`

const PlayerWrapper = styled.div`
grid-column: 2/3;
grid-row: 1;


display: grid;
grid-template-columns: 1fr;
grid-template-rows: 20% 1fr 20%;
`


const InfoWrapper = styled.div`
grid-column: 1/2;
grid-row: 1;
display: flex;
align-items: center;
flex-direction: column;
`


const Wrapper = styled.div`
display: grid;
height: 100%;
width: 100%;
grid-template-rows: 1fr;
grid-template-columns: 1fr 1fr;
grid-gap: 10px;
`

