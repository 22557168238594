import React, {useState, useMemo, useEffect} from "react"

import {setCookieData, getCookieData} from "../utils/cookie"
import {USER_DATA} from "../utils/constants";

export const RoomContext = React.createContext()

export const RoomProvider = props => {


    //state for session context
    const [roomData, setRoomData] = useState({
        devMode: false


    })
    useEffect(() => {

        if (roomData.userID && roomData.teamID && roomData.userName && roomData.userEmail && roomData.roomCode) {
            let userCookie = getCookieData(USER_DATA)
            if (!userCookie || !userCookie.userID || !userCookie.userName || !userCookie.userEmail || !userCookie.teamID || !userCookie.roomCode) {
                let result = setCookieData(USER_DATA, {
                    userID: roomData.userID,
                    teamID: roomData.teamID,
                    userName: roomData.userName,
                    userEmail: roomData.userEmail,
                    roomCode: roomData.roomCode.toString()
                })
            }
        }
    }, [roomData, getCookieData(USER_DATA)])
    //nextPage
    const nextPage = (nextPage) => {
        if (nextPage) {
            setRoomData(data => {
                return {
                    ...data,
                    currentPage: nextPage
                }
            })
        } else {
            setRoomData(data => {
                return {
                    ...data,
                    currentPage: data.currentPage ? data.currentPage + 1 : 1
                }
            })
        }
    }


//Function to change whatever is saved in the session context
    const changeRoomData = (data) => {
        setRoomData(roomData => {

            let ownData = {}
            if (data.users) {
                data.users.forEach(user => {
                    if (user.userID === roomData.userID) {
                        ownData = user
                        if (roomData.teamName && !ownData.teamName) ownData.teamName = roomData.teamName
                    }
                })
            }

            return {
                ...roomData,
                ...ownData,
                teamName: roomData.teamName,
                ...data
            }
        })

    }


//use memo to prevent unnecessary re-rendering
    let roomValue = useMemo(() => ({
        roomData,
        changeRoomData,
        nextPage


    }), [roomData, changeRoomData, nextPage])


    return (
        <RoomContext.Provider value={roomValue}>
            {props.children}
        </RoomContext.Provider>
    )
}



