import React from 'react';
import styled from "styled-components"
import Image from "../../public/images/join_backgroundjpg.jpg"

const JoinWrapper = (props) => {
    return (
        <Wrapper>
            {props.children}
        </Wrapper>
    );
}

export default JoinWrapper;


const Wrapper = styled.div`
  background: url(${Image}) no-repeat center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
`












