import React, {useContext} from 'react';
import styled from "styled-components"
import {Default} from "react-spinners-css"
import {LoadingContext} from "../../context/LoadingConext";

const Loader = (props) => {

    let {loading, text} = useContext(LoadingContext)
    

    return (
        loading ? (
            <Wrapper>
                <Default color={"white"}/>
                {text && (
                    <InfoWrapper>
                        {text}
                    </InfoWrapper>
                )}
            </Wrapper>
        ) : null
    );

}

export default Loader;


const InfoWrapper = styled.div`
  margin-top: 40px;
  color: white;

`

const Wrapper = styled.div`
  z-index: 99;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, .7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

`