import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components"
import {OrganizationContext} from "../../../context/OrganizationContext";
import {RoomContext} from "../../../context/RoomContext";
import JoinWrapper from "../../atoms/JoinWrapper";
import GameWrapper from "../../atoms/GameWrapper";
import {calculateScore} from "../../../utils/scorer";


const EndPage = (props) => {


    const {roomData} = useContext(RoomContext)
    const {organizationData} = useContext(OrganizationContext)

    const [teams, setTeams] = useState(null)
    //const [user, setUser] = useState(null)

    const [ownTeam, setOwnTeam] = useState(null)

    const [place, setPlace] = useState(0)

    const [hasData, setHasData] = useState(false)


    useEffect(() => {
        props.getEndRating()
    }, [])

    useEffect(() => {


        if (roomData.rating) {
            let teamsTemp = []
            roomData.rating.forEach(team => {
                let result = calculateScore(team)

                if (result._id === roomData.teamID) {
                    result.users.forEach(user => {
                        /* if (user._id === roomData.userID) {
                             setUser(user)
                         }*/
                    })
                    setOwnTeam(result)
                }
                teamsTemp.push(result)
            })


            let sortedTeams = teamsTemp.sort((a, b) => {
                return b.teamTotal - a.teamTotal
            })
            sortedTeams.forEach((team, index) => {
                if (team._id === roomData.teamID) {
                    setPlace(index + 1 === 1 ? "eerste" : index + 1 === 2 ? "tweede" : index + 1 === 3 ? "derde" : index + 1)
                }
            })
            setTeams(teamsTemp)


            setHasData(true)

        }

    }, [roomData])


    const getUsers = () => {
        let sortedUsers = ownTeam.users.sort((a, b) => {
            return b.total - a.total
        })

        let result = []

        sortedUsers.forEach((user, index) => {

            if (user.done) {
                let elem = (
                    <SingleUserWrapper key={user._id}>
                        <SingleUserDataRank>
                            <RankingText>{index + 1}.</RankingText>
                        </SingleUserDataRank>
                        <SingleUserDataName>
                            <RankingText>{user.name}</RankingText>
                        </SingleUserDataName>
                        <SingleUserDataPoints>
                            <RankingText>{user.total} punten</RankingText>
                        </SingleUserDataPoints>
                    </SingleUserWrapper>

                )
                result.push(elem)
            }
        })

        return result

    }

    /* const getFeedback = () => {

         let feedback = []

         if (user.firstRating < 15) {

             feedback.push(organizationData.titleFirstAssignment + "/")
         }

         if (user.secondRating < 15) {
             feedback.push(organizationData.titleSecondAssignment + "/")
         }

         if (user.thirdRating < 15) {
             feedback.push(organizationData.titleThirdAssignment + "/")
         }

         if (feedback.length > 0) {
             //removes the  comma of the last element
             try {
                 feedback[feedback.length - 1] = feedback[feedback.length - 1] = feedback[feedback.length - 1].substring(0, feedback[feedback.length - 1].length - 1);
             } catch (e) {
                 console.error(e)
             }

             return (
                 <div>
                     <SuggestionHeader>Op basis van jouw beoordelingen adviseren we je om je nog wat
                         verder te verdiepen in <SuggestionSpan>
                             {feedback.map(topic => {
                                 return topic
                             })}
                         </SuggestionSpan>
                     </SuggestionHeader>
                 </div>
             )
         }
     }*/
    if (hasData) {
        return (
            <GameWrapper>
                <GridWrapper>
                    <HeaderWrapper><Header>Bedankt voor je deelname aan Heel Holland Hackt</Header></HeaderWrapper>
                    <TeamResultWrapper>
                        <TeamRankingHeader>Teamprestatie
                            "{ownTeam.name}": <ScoreSpan> {ownTeam.teamTotalAverage} punten
                                (gemiddeld) </ScoreSpan></TeamRankingHeader>
                        <TeamRankingHeader> Daarmee staan jullie op dit
                            moment: <ScoreSpan>{place} <VanSpan>(van {teams.length})</VanSpan> </ScoreSpan>
                        </TeamRankingHeader>

                        <TeamCompareHeader>Wie had de opdrachten het beste ingeschat?</TeamCompareHeader>
                        <UserTable>
                            <tbody>
                            {getUsers()}
                            </tbody>
                        </UserTable>

                    </TeamResultWrapper>

                    {/*  <SuggestionWrapper>
                        {getFeedback()}
                    </SuggestionWrapper>*/}
                    {/*             <OwnScoreWrapper>
                        <PersonalScoreHeader>Uw persoonlijke totaalscore is: {user.total} punten</PersonalScoreHeader>
                        <UserRankingText>{organizationData.titleFirstAssignment} - {user.firstRating}/25
                        </UserRankingText>
                        <UserRankingText>{organizationData.titleSecondAssignment} - {user.secondRating}/25
                        </UserRankingText>
                        <UserRankingText>{organizationData.titleThirdAssignment} - {user.thirdRating}/25
                        </UserRankingText>
                        <UserRankingText>Quiz - {user.quizRating}/15</UserRankingText>
                        <UserRankingText>Sterk wachtwoord - {user.passwordRating}/10</UserRankingText>
                    </OwnScoreWrapper>*/}
                    <FeedbackWrapper>
                        <SurveyHeader>{roomData.userName} wat vond je van Heel Holland
                            Hackt?</SurveyHeader>
                        <FormWrapper>
                            <iframe
                                title={"feedback"}
                                autoFocus={false}
                                src={`https://cyberbarometer.brooklynpartners.nl/zs/3mh3n8?organisatie=${organizationData.orgCode}?team=${roomData.teamName}`}
                                frameBorder={0}
                                marginHeight={0}
                                marginWidth={0}
                                scrolling={"auto"}
                            />
                        </FormWrapper>
                    </FeedbackWrapper>
                </GridWrapper>
            </GameWrapper>
        );
    } else {
        return (<JoinWrapper>
            Error
        </JoinWrapper>)
    }

}

const SingleUserDataRank = styled.td`

`

const SingleUserDataName = styled(SingleUserDataRank)`

`

const SingleUserDataPoints = styled(SingleUserDataRank)`

`


const SingleUserWrapper = styled.tr`
`

const UserTable = styled.table`
  width: 100%;
  max-width: 550px;

`

const OwnScoreWrapper = styled.div`
  grid-row: 3/6;
  grid-column: 8/12;
`


const SuggestionSpan = styled.span`

`
const SuggestionHeader = styled.h4`
  color: white;
  font-size: 1.2rem;
`

const RankingText = styled.p`
  color: white;
  font-size: 24px;
  font-weight: 400;
  margin: 4px 0;


  @media (max-width: 960px) {
    font-size: 18px;
  }
  @media (max-height: 900px) {
    font-size: 14px;
  }
`

const UserRankingText = styled(RankingText)`
  font-size: 22px;
  margin: 10px 0;

  @media (max-width: 960px) {
    font-size: 18px;
  }
  @media (max-height: 900px) {
    font-size: 14px;
  }
`


const TeamCompareHeader = styled.h4`
  color: white;
  text-align: left;
  margin-top: 34px;
  font-weight: 400;
`

const TeamRankingHeader = styled.h3`
  color: white;
  text-align: left;
  font-weight: 600;
  font-size: 1.6rem;
  @media (max-height: 900px) {
    font-size: 18px;
  }
`

const ScoreSpan = styled.span`
  font-weight: 300;
`

const VanSpan = styled.span`
  font-size: 1.2rem;

`

const PersonalScoreHeader = styled.h3`
  color: white;
  text-align: left;
  font-weight: 600;
  @media (max-width: 960px) {
    font-size: 18px;
  }
  @media (max-height: 900px) {
    font-size: 14px;
  }
`

const SurveyHeader = styled(PersonalScoreHeader)`
  text-align: center;
  font-size: 24px;
  @media (max-width: 960px) {
    font-size: 18px;
  }
  @media (max-height: 900px) {
    font-size: 14px;
  }
`

const FormWrapper = styled.div`
  background-color: black;

  height: 100%;


  & > iframe {
    height: 100%;
    width: 100%;
  }
`

const FeedbackWrapper = styled.div`
  grid-row: 3/11;
  padding-left: 20px;
  padding-right: 20px;
  grid-column: 8/12;

`

const SuggestionWrapper = styled.div`
  grid-row: 10/12;
  grid-column: 2/7;

`

const TeamResultWrapper = styled.div`
  grid-row: 3/10;
  grid-column: 2/7;
  overflow: auto;
`


const Header = styled.h1`
  text-align: center;
  color: white;
  margin: 0;
`

const HeaderWrapper = styled.div`
  grid-row: 1/2;
  display: flex;
  align-items: flex-end;
  grid-column: 3/11;
  justify-content: center;

`

const GridWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  overflow: auto;
  
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);


`

export default EndPage;