import React, {useEffect, useContext} from 'react';
import {RoomContext} from "../../context/RoomContext";
import {LoadingContext} from "../../context/LoadingConext";
import zxcvbn from "zxcvbn"
import {OrganizationContext} from "../../context/OrganizationContext";
import * as ReactGA from "react-ga";
import {CATEGORY_CONNECTION_ERROR, CATEGORY_GAME} from "../../analytics/constants";

const ListenerProvider = (props) => {

    let {roomData, changeRoomData} = useContext(RoomContext)
    let {startLoading, stopLoading, setLoadingText} = useContext(LoadingContext)
    let {organizationData} = useContext(OrganizationContext)


    let reconnecting = false
    let socketID = 0

    const socketExists = (socket) => {
        return new Promise((resolve, reject) => {
            if (socket) {
                return resolve(true)
            }

            return reject("Not existing")
        })
    }

    useEffect(() => {
        let socket = props.socket
        if (socket) {

            socket.on("changeRoomData", data => {
                changeRoomData(data)
            })

            socket.on("gameStarted", ({teamName, teamPassword}) => {
                ReactGA.event({
                    category: CATEGORY_GAME,
                    action: 'Game started',
                    value: {
                        teamName,
                        teamPassword,
                    }
                });
                changeRoomData({
                    teamName,
                    teamPassword,
                    currentPage: 1,
                    waitingRoom: false
                })

            })


            socket.on('disconnect', (reason) => {
                reconnecting = true
                startLoading()

                ReactGA.event({
                    category: CATEGORY_CONNECTION_ERROR,
                    action: 'Websocket disconnected',
                    value: {
                        reason
                    }
                });

                setLoadingText("De verbinding is verbroken. Opnieuw verbinden ...")
                //  console.log("Disconnected")
                if (reason === 'io server disconnect') {
                    // the disconnection was initiated by the server, you need to reconnect manually
                    socket.connect();
                }
                // else the socket will automatically try to reconnect
            });

            socket.on('connect', () => {
                //   console.log("Connected")
                stopLoading()
                if (reconnecting) {
                    ReactGA.event({
                        category: CATEGORY_CONNECTION_ERROR,
                        action: 'Websocket reconnected successfully'
                    });
                    //  console.log("successfully reconnected")
                    let oldSocketID = socketID
                    //  console.log("old Socket ID", oldSocketID)
                    reconnecting = false

                    let data = {
                        oldSocketID,
                        roomCode: roomData.roomCode,
                        userName: roomData.userName,
                        userEmail: roomData.userEmail,
                        orgCode: organizationData.orgCode
                    }
                    handleSocketReconnect(data)
                }
                socketID = socket.id
                //  console.log("new socket ID", socketID)
            });
        }

    }, [props.socket])


    const getEndRating = () => {
        socketExists(props.socket).then(() => {
            props.socket.emit("endRating")
        })
    }

    const handleSocketReconnect = (data) => {
        socketExists(props.socket).then(() => {
            props.socket.emit("reconnected", data)
        })
    }

    const handleNextPage = (userID, page) => {
        socketExists(props.socket).then(() => {
            props.socket.emit("userPageSwitch", {userID: userID, nextPage: page})
        })
    }

    const handleVoteComplete = (votingRound, result) => {
        socketExists(props.socket).then(() => {
            props.socket.emit("voteComplete", {votingRound, result})
        })
    }

    const handleSyncPageSwitch = (newPage) => {
        socketExists(props.socket).then(() => {
            props.socket.emit("syncPageSwitch", {newPage})
        })
    }


    const handleStartButtonClicked = () => {
        socketExists(props.socket).then(() => {
            props.socket.emit("chooseTeamName")
        })
    }


    const handleCancelTeamButtonClicked = () => {
        socketExists(props.socket).then(() => {
            props.socket.emit("cancelChooseTeamName")
        })
    }


    const startGame = (teamName, teamPassword, roomCode) => {
        socketExists(props.socket).then(() => {
            let passwordScore = zxcvbn(teamPassword).score
            props.socket.emit("startGame", {teamName, teamPassword: passwordScore, roomCode})
        }).catch(error => {
            console.error(error)

        })
    }


    //Give props to children of Listener Provider
    let children = React.Children.map(props.children, child => {
        //Add specific props if its the game component
        if (child.key === "game") {
            return React.cloneElement(child, {
                socket: props.socket,
                handleVoteComplete: handleVoteComplete,
                handleSyncPageSwitch: handleSyncPageSwitch,
                getEndRating: getEndRating,
                handleNextPage: handleNextPage
            })
        } else if (child.key === "waitingRoom") {
            //add specific props if its waitingRoom component
            return React.cloneElement(child, {
                socket: props.socket,
                handleStartButtonClicked: handleStartButtonClicked,
                handleCancelTeamButtonClicked: handleCancelTeamButtonClicked,
                startGame: startGame
            })
        }
    })

    return (
        <div>
            {children}
        </div>
    );
}


export default ListenerProvider;

