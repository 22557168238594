import React, {useContext, useEffect} from 'react';
import styled from "styled-components";
import {RoomContext} from "./context/RoomContext"
import {OrganizationContext} from "./context/OrganizationContext"
import ReactGA from "react-ga"
import {useHistory, withRouter} from "react-router-dom"


const App = (props) => {

    const history = useHistory()

    useEffect(() => {
        console.log(history)

        let unlisten = history.listen((location, action) => {
            console.log(location)
            console.log("changed")
            ReactGA.pageview(location.pathname + location.search);
            console.log(location.pathname)
        });

        return () => unlisten

    }, [history])


    let {roomData, changeRoomData} = useContext(RoomContext)
    let {organizationData} = useContext(OrganizationContext)

    const handleDevPrevPage = () => {
        let newPage = roomData.currentPage - 1
        changeRoomData({
            currentPage: newPage
        })
     //   toast.info(`Current page ${newPage}`)
    }

    const handleDevNextPage = () => {
        let newPage = roomData.currentPage + 1
        changeRoomData({
            currentPage: newPage
        })
        //  toast.info(`Current page ${newPage}`)
    }
    return (
        <div>
            {props.children}
            {organizationData?.devMode && (
                <div>
                    <DevButtonForward onClick={() => handleDevNextPage()}>
                        Demo volgende
                    </DevButtonForward>
                    <DevButtonBackward onClick={() => handleDevPrevPage()}>
                        Demo vorige
                    </DevButtonBackward>
                </div>
            )}
        </div>
    );
}


export default withRouter(App);

const DevButtonForward = styled.button`
  position: absolute;
  padding: 8px;
  outline: none;
  background-color: white;
  bottom: 100px;
  right: 100px;
`

const DevButtonBackward = styled(DevButtonForward)`
  left: 100px;
  bottom: 100px;
`