import React, {useState, useContext} from 'react';
import styled from "styled-components";
import JoinWrapper from "../atoms/JoinWrapper";
import InputField from "../atoms/InputField";
import ArrowRight from "../../public/icons/arrow_right.svg";
import {login} from "../../axios";
import {toast} from "react-toastify"
import {OverviewContext} from "../../context/OverviewContext";
import {useHistory} from "react-router-dom";
import Button from "../atoms/Button"


const Login = (props) => {

    let history = useHistory()
    const {overviewData, changeOverviewData} = useContext(OverviewContext)


    const [ready, setReady] = useState(false)
    const [code, setCode] = useState("")
    const [password, setPassword] = useState("")


    const handleCodeInput = (value) => {
        if (value.length > 0 && password.length > 0) {
            setReady(true)
        } else {
            setReady(false)
        }
        setCode(value)
    }

    const handlePasswordInput = (value) => {
        if (value.length > 0 && code.length > 0) {
            setReady(true)
        } else {
            setReady(false)
        }
        setPassword(value)
    }

    const handleLogin = (e) => {
        e?.preventDefault()
        if (ready) {
            login(code, password).then((res) => {
                delete res.success
                delete res.message
                changeOverviewData(res)
                history.push(`/team`)
            }).catch((error) => {
                console.log(error)
                toast.error("Verkeerde code of wachtwoord")
            })
        }
    }


    return (
        <JoinWrapper>
            <TeamOverviewButton onClick={() => history.push("/")}>Terug</TeamOverviewButton>
            <Wrapper>
                <Header>Organisatie scorebord</Header>
                <InputWrapper onSubmit={(e) => handleLogin(e)}>
                    <InputField handleInput={value => handleCodeInput(value)} placeholder={"Organisatiecode"}
                                type={"text"} textAlign={"left"} value={code}/>
                    <InputField handleInput={value => handlePasswordInput(value)} placeholder={"Wachtwoord"}
                                type={"password"} textAlign={"left"} value={password}/>
                    <Button text={"Aanmelden"} handleClick={handleLogin}/>
                </InputWrapper>
            </Wrapper>
        </JoinWrapper>
    );
}


const TeamOverviewButton = styled.button`

  position: absolute;
  top: 40px;
  left: 40px;
  cursor: pointer;
  font-size: 1.2rem;

  color: white;
  background-color: black;
  border-radius: 4px;
  border: 2px solid white;
  padding: 8px 12px;
  font-weight: 400;
`


const Header = styled.h1`
  color: white;

`

const InputWrapper = styled.form`
  display: flex;
  margin-top: 38px;
  justify-content: center;
  align-items: center;
  flex-direction: column;


  & > div {
    padding: 8px;

    margin-bottom: 20px;
  }

  & button {
    margin: 40px auto;
  }
`

const ContinueButton = styled.button`
  background: url(${ArrowRight}) ${props => props.ready ? "white" : "gray"} center no-repeat;
  background-size: 34px;
  padding: 32px;
  border: none;
  border-radius: 50px;
  cursor: ${props => props.ready ? "pointer" : "default"};


  &:active {
    box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
  }
`
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
`


export default Login;