import React from 'react';
import styled from "styled-components"

/**
 * @param {function} handleInput - input handler, receiving field input
 * @param {String} background - color code for background
 * @param {String} type - input type e.g. text, password, email etc
 * @param {String} textAlign - text align
 * @param {String} value - value of input field
 * */
const InputField = ({handleInput, value, placeholder, textAlign = "left", background = "white", type = "text"}) => {
    return (
        
        <Wrapper backgroundColor={background}>
            <Input onChange={e => handleInput(e.target.value)} value={value} textalign={textAlign}
                   placeholder={placeholder ? placeholder : ""}
                   type={type}/>
        </Wrapper>
    );
}

export default InputField;

const Input = styled.input`
  font-family: Montserrat, sans-serif;
  padding: 12px 12px;
  outline: none;
  font-size: 24px;
  border: none;
  background: transparent;
  text-align: ${props => props.textalign};
`

const Wrapper = styled.div`
   background: ${props => props.backgroundColor};
   border-radius: 10px;
   display: flex;
   margin: 0 5px;
`