import React, {useContext} from 'react';
import styled from "styled-components";
import {RoomContext} from "../../context/RoomContext";
import {OrganizationContext} from "../../context/OrganizationContext";


const TeamHeader = (props) => {

    let {roomData} = useContext(RoomContext)
    let {organizationData} = useContext(OrganizationContext)


    return (
        <Wrapper>
            <TeamName>Team {roomData.teamName}</TeamName>
        </Wrapper>
    );
}

export default TeamHeader;


const Wrapper = styled.div`

`
const TeamName = styled.h2`
  margin: 0;
  padding-top: 20px;
  padding-left: 40px;
  color: white;
  font-size: 26px;
  font-weight: 600;

`