import React, {useContext, useState, useEffect} from 'react';
import styled from "styled-components"
import Player from "../../molecules/Player";
import Timer from "../../molecules/TimeBar"
import {OrganizationContext} from "../../../context/OrganizationContext";
import {RoomContext} from "../../../context/RoomContext";

const SpeedVoting = ({speedRound, showOptionsAfter, stopVideoAfter, voteComplete, forwardPage}) => {

    let {organizationData} = useContext(OrganizationContext)
    let {roomData, nextPage} = useContext(RoomContext)


    const [intervalID, setIntervalID] = useState(-1)

    //state for right answer
    const [optionRight, setOptionRight] = useState(null)

    //state for wrong answer
    const [optionWrong, setOptionWrong] = useState(null)

    //state for shwoing options and timer
    const [showOptions, setShowOptions] = useState(false)

    //controlls if video is playing or not
    const [isVideoPlaying, setIsVideoPlaying] = useState(true)


    let timerLength = 30

    let successPoints = 1

    let failPoints = 0

    useEffect(() => {

        //setting answers based on which speed vote round it is
        switch (speedRound) {
            case 1:
                setOptionRight(organizationData.optionsOneRight)
                setOptionWrong(organizationData.optionsOneWrong)
                break;
            case 2:
                setOptionRight(organizationData.optionsTwoRight)
                setOptionWrong(organizationData.optionsTwoWrong)
                break;
            case 3:
                setOptionRight(organizationData.optionsThreeRight)
                setOptionWrong(organizationData.optionsThreeWrong)
                break;
            case 4: //for testing purposes
                setOptionRight("Right answer")
                setOptionWrong("Wrong answer")
                break;
        }
        return () => clearInterval(intervalID)
    }, [speedRound])


    //function to get n random items of an array
    function getRandom(arr, n) {
        let result = new Array(n),
            len = arr.length,
            taken = new Array(len);
        if (n > len)
            throw new RangeError("getRandom: more elements taken than available");
        while (n--) {
            let x = Math.floor(Math.random() * len);
            result[n] = arr[x in taken ? taken[x] : x];
            taken[x] = --len in taken ? taken[len] : len;
        }
        return result;
    }

    const handleStarted = () => {

    }

    const handleProgress = (progress) => {


        //handles when options need to be shown
        if (progress.playedSeconds >= showOptionsAfter) {
            setShowOptions(true)
        }

        if (progress.playedSeconds >= stopVideoAfter) {
            setIsVideoPlaying(false)
        }
    }


    const handleEnded = () => {
        forwardPage()

    }

    //handles when next page
    const handleTimerProgress = (passedSeconds) => {
        if (passedSeconds >= timerLength) {
            forwardPage()
        }
    }


    const handleSpeedVotingClicked = (right) => {

        //the 4, 5 and 6 are representing the 4th, 5th and 6th voting inside the game, including the 3 normal rankings before
        let round = speedRound === 1 ? 4 : speedRound === 2 ? 5 : 6

        if (right) {
            voteComplete(round, successPoints)
        } else {
            voteComplete(round, failPoints)
        }
        forwardPage()
    }

    //returns options with random order of questions
    const getOptions = () => {
        let optionRightElement = <OptionTextRight key={1}
                                                  onClick={() => handleSpeedVotingClicked(true)}>{optionRight}</OptionTextRight>
        let optionWrongElement = <OptionTextWrong key={0}
                                                  onClick={() => handleSpeedVotingClicked(false)}>{optionWrong}</OptionTextWrong>
        //randomizes array
        return getRandom([optionRightElement, optionWrongElement], 2)
    }
    
    return (
        <Wrapper>
            <Player
                playing={isVideoPlaying}
                url={speedRound === 1 ? organizationData.optionsVideoOne : speedRound === 2 ? organizationData.optionsVideoTwo : organizationData.optionsVideoThree}
                onStart={() => handleStarted()}
                onEnded={() => handleEnded()}
                onProgress={progress => handleProgress(progress)}
            />
            {showOptions && (
                <>
                    <OptionsWrapper>
                        {optionWrong && optionRight && getOptions()}
                    </OptionsWrapper>
                    <TimerWrapper>
                        <Timer
                            timeInSeconds={timerLength}
                            setInterID={(id) => setIntervalID(id)}
                            onProgress={(passedSeconds) => handleTimerProgress(passedSeconds)}
                        />
                    </TimerWrapper>
                </>
            )}
        </Wrapper>
    );
}

export default SpeedVoting;


const OptionTextRight = styled.button`
  cursor: pointer;
  color: white;
  background-color: black;
  border-radius: 4px;
  border: 2px solid white;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  padding: 24px 18px;
`

const OptionTextWrong = styled(OptionTextRight)`

`

const OptionsWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 310px;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const TimerWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`

const Wrapper = styled.div`
  position: relative;


`