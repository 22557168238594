import React, {useContext, useEffect, useState} from 'react';
import JoinWrapper from "../atoms/JoinWrapper";
import {OrganizationContext} from "../../context/OrganizationContext";
import {useHistory, useParams} from "react-router-dom"
import {companyValidator} from "../../utils/companyValidator";
import styled from "styled-components"
import InputField from "../atoms/InputField";
import Button from "../atoms/Button";
import ArrowRight from "../../public/icons/arrow_right.svg";
import {toast} from "react-toastify";
import {validateEmail} from "../../utils/emailValidator";
import {RoomContext} from "../../context/RoomContext";
import {MEDIA_URL, USER_DATA} from "../../utils/constants";
import * as ReactGA from "react-ga";
import {CATEGORY_GAME} from "../../analytics/constants";

import {getCookieData, resetCookie} from "../../utils/cookie";

const Join = () => {


    const history = useHistory()
    const {orgCode, roomCode} = useParams()

    let {organizationData, changeOrganizationData} = useContext(OrganizationContext)
    let {roomData, changeRoomData} = useContext(RoomContext)


    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [ready, setReady] = useState(false)
    const [cookieAccepted, setCookieAccepted] = useState(false)

    useEffect(() => {
        if (ready && name.length > 0 && email.length > 0) {
            handleGenerateRoom()
        }
    }, [cookieAccepted])


    useEffect(() => {

        let userCookie = getCookieData(USER_DATA)


        if (roomData.devMode) {
            companyValidator(orgCode, history).then(organizationData => {
                changeOrganizationData(organizationData)
                changeRoomData({
                    roomCode,
                    userName: "Ismael",
                    userEmail: "ismaelabdelatif@gmail.com"
                })
                history.push(`/game/${orgCode}/${roomCode}`)
            })
            return
        }
        if (!organizationData._id && orgCode && roomCode) {
            companyValidator(orgCode, history).then(organizationData => {
                changeOrganizationData(organizationData)
                changeRoomData({roomCode})
            }).catch(err => {
                console.error(err)
                toast.error("Fout opgetreden, probeer het nog eens.")
                history.push("/")
            })
        }

        if (userCookie && userCookie.userName && userCookie.userEmail && userCookie.roomCode === roomCode) {
            setName(userCookie.userName)
            setEmail(userCookie.userEmail)
            setReady(true)
            setCookieAccepted(true)
        } else {
            resetCookie(USER_DATA)
        }


        // eslint-disable-next-line
    }, [])



    const handleNameInput = (value) => {
        setName(value)
        if (email.length > 0 && value.length > 0) {
            setReady(true)
            return
        }
        setReady(false)
    }

    const handleEmailInput = (value) => {
        setEmail(value)
        if (name.length > 0 && value.length > 0) {
            setReady(true)
            return
        }
        setReady(false)
    }
    const handleGenerateRoom = (e) => {
        e?.preventDefault()
        if (ready) {
            let validEmail = validateEmail(email)
            if (validEmail) {
                ReactGA.event({
                    category: CATEGORY_GAME,
                    action: 'Room Created or Joined',
                    value: {
                        userName: name,
                        userEmail: email
                    }
                });
                changeRoomData({
                    userName: name,
                    userEmail: email
                })
                history.push(`/game/${orgCode}/${roomCode}`)
                return
            }
            toast.error("Voer een geldig e-mailadres in")
        }
    }


    return (
        <JoinWrapper>
            <Wrapper>
                <Header>Welkom</Header>
                    {organizationData.orgName && <Header>{organizationData.orgName}</Header>}
                <CompanyImageWrapper>
                    {organizationData.logo && <CompanyImage src={MEDIA_URL + organizationData.logo}/>}
                </CompanyImageWrapper>
                <InputWrapper onSubmit={(e) => handleGenerateRoom(e)}>
                    <InputField handleInput={value => handleNameInput(value)} placeholder={"Vul uw naam in"}
                                type={"text"} textAlign={"left"} value={name}/>
                    <InputField handleInput={value => handleEmailInput(value)} placeholder={"Voer uw e-mail in"}
                                type={"email"} textAlign={"left"} value={email}/>

                    <Button text={"Start Heel Holland Hackt"}/>

                </InputWrapper>
            </Wrapper>
        </JoinWrapper>
    );
}

export default Join;

const CompanyImage = styled.img`
max-height: 150px;

`

const CompanyImageWrapper = styled.div`
  margin: 40px 0 100px 0;
  height: 150px;
`
const InputWrapper = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  
  & div {
    margin-bottom: 18px;
  }

  & button {
    margin: 22px auto;
  }
`


const Header = styled.h1`
color: white;
letter-spacing: 2px;
font-size: 48px;
margin: 0;
&:last-of-type{
margin-top: 18px;
font-size: 54px;
}
`

const ContinueButton = styled.button`
  background: url(${ArrowRight}) ${props => props.ready ? "white" : "gray"} center no-repeat;
  background-size: 34px;
  padding: 32px;
  border: none;
  margin-left: 18px;
  border-radius: 50px;
  cursor: ${props => props.ready ? "pointer" : "default"};


  &:active {
    box-shadow: inset 0 0 4px 1px rgba(0, 0, 0, 0.3);
  }
}
`

const Wrapper = styled.div`
display: flex;
height: 100%;
width: 100%;
justify-content: center;
align-items: center;
flex-direction: column;
`

