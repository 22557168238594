import {createGlobalStyle} from "styled-components";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;800&display=swap');

  body {
    margin: 0;
    padding: 0;
    font-family: Montserrat, sans-serif;
    box-sizing: border-box;
  }
`;


export default GlobalStyle