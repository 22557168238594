import React, {useMemo, useState} from "react"


export const LoadingContext = React.createContext()

export const LoadingProvider = props => {
    //state for loading context
    const [loading, setLoading] = useState(false)
    const [text, setText,] = useState("")

//function to start loading
    const startLoading = () => {
        setLoading(true)
    }

//function to stop loading
    const stopLoading = () => {
        setLoading(false)
        setText("")
    }

    const setLoadingText = (text) => {
        setText(text)
    }

//use memo to prevent unnecessary re-rendering
    let loadingValue = useMemo(() => ({
        setLoadingText,
        startLoading,
        stopLoading,
        text,
        loading,
    }), [loading, text, stopLoading, startLoading, setLoadingText])

    return (
        <LoadingContext.Provider value={loadingValue}>
            {props.children}
        </LoadingContext.Provider>
    )
}
