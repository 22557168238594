import axios from "axios"
import {HOST} from "../utils/constants";


let endpoint = HOST + "/api/v1"

export const checkCompany = (orgCode) => {
    return new Promise((resolve, reject) => {
        axios.post(endpoint + "/checkCompany", {orgCode})
            .then(result => {
                resolve(result.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

//fetching company data
export const getCompany = (orgCode) => {
    return new Promise((resolve, reject) => {
        axios.post(endpoint + "/getCompanyData", {orgCode})
            .then(result => {
                resolve(result.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}


//fetching company data
export const login = (orgCode, password) => {
    return new Promise((resolve, reject) => {
        axios.post(endpoint + "/login", {orgCode, password})
            .then(result => {
                resolve(result.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

//fetching company data
export const getTeams = (token) => {
    return new Promise((resolve, reject) => {

        axios.post(endpoint + "/teams", {}, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then(result => {
                resolve(result.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}


//hide teams
export const hideTeam = (token, teamID) => {
    return new Promise((resolve, reject) => {

        axios.post(endpoint + "/hideTeam", {teamID: teamID}, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then(result => {
                resolve(result.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

//hide teams
export const showTeam = (token, teamID) => {
    return new Promise((resolve, reject) => {

        axios.post(endpoint + "/showTeam", {teamID: teamID}, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then(result => {
                resolve(result.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}