import React, {useContext, useEffect, useState} from 'react';
import Game from "./Pages/Game";
import WaitingRoom from "./WaitingRoom";
import SocketManager from "../../socket";
import {useHistory, useParams} from "react-router-dom";
import {OrganizationContext} from "../../context/OrganizationContext";
import {RoomContext} from "../../context/RoomContext";
import {LoadingContext} from "../../context/LoadingConext";
import {companyValidator} from "../../utils/companyValidator";
import ListenerProvider from "./ListenerProvider";


const Index = ({location}) => {
    const sm = new SocketManager()

    let history = useHistory()
    let {orgCode, roomCode} = useParams()
    let {organizationData, changeOrganizationData} = useContext(OrganizationContext)
    let {roomData, changeRoomData} = useContext(RoomContext)
    let [socket, setSocket] = useState(null)
    useEffect(() => {

        window.history.pushState(null, document.title, location.href);
        window.addEventListener('popstate', function (event) {
            window.history.pushState(null, document.title, location.href);
        });

        document.oncontextmenu = function (e) {
            if (e.button == 2) {
                e.preventDefault();
                return false;
            }

        }


        changeRoomData({
            waitingRoom: true
        })
        if (roomData.devMode) {
            if (!roomData.userName || !roomData.userEmail) {
                history.push(`/join/${orgCode}/${roomCode}`)
            } else {
                companyValidator(orgCode, history).then(organizationData => {
                    changeOrganizationData({...organizationData})
                    changeRoomData({roomCode: roomCode, orgCode})

                    let socket = sm.createSocketAndJoinRoom(roomCode, roomData.userName, roomData.userEmail, orgCode)
                    setSocket(socket)
                    changeRoomData({
                        waitingRoom: false
                    })
                    socket.emit("startGame", {teamName: "yaaay", teamPassword: 1, roomCode})
                })
            }
            return
        }
        //check if user name and user email are available, if not, get sent back to join
        if (!roomData.userName || !roomData.userEmail) {
            history.push(`/join/${orgCode}/${roomCode}`)
        } else {
            //fetches company data in case user jumps directly on this page without going through the setup process
            if (!organizationData._id) {
                companyValidator(orgCode, history).then(organizationData => {
                    changeOrganizationData({...organizationData})
                    changeRoomData({roomCode: roomCode, orgCode})
                })
            }

            //setting up new socket connection
            let socket = sm.createSocketAndJoinRoom(roomCode, roomData.userName, roomData.userEmail, orgCode)
            setSocket(socket)
        }
            // eslint-disable-next-line
        }, []
    )


    return (
        <ListenerProvider socket={socket}>
            {roomData.waitingRoom ?
                <WaitingRoom leaveWaitingRoom={() => changeRoomData({waitingRoom: false})} key={"waitingRoom"}/> :
                <Game key={"game"}/>}
        </ListenerProvider>
    );
}

export default Index;
