//normal rating = 75
// good password = 10

// 3 * 5


let roundNumber = 4


// first, second, third, round average per team
// total points per user, per team
// average per player per team


//rounds number to the last 2 digits after ,
const round = (num) => {
    return Math.round((num + Number.EPSILON) * 100) / 100
}


//calculates the sum of the options and adds points in case sum > 0
const calcOptions = (one, two, three) => {
    return one * 5 + two * 5 + three * 5
}


const calcUserScore = (user) => {

    let total = 0;
    total += user.firstRating ? user.firstRating : 0
    total += user.secondRating ? user.secondRating : 0
    total += user.thirdRating ? user.thirdRating : 0
    total += user.passwordRating ? user.passwordRating : 0

    let optionOne = user.optionOne ? user.optionOne : 0
    let optionTwo = user.optionTwo ? user.optionTwo : 0
    let optionThree = user.optionThree ? user.optionThree : 0

    total += calcOptions(optionOne, optionTwo, optionThree)

    return {total: total, average: round(total / roundNumber)}
}


export const calculateScore = (t) => {

    let users = t.users
    let usersLength = users.length

    let countDone = 0;

    let firstRating = 0
    let secondRating = 0
    let thirdRating = 0
    let quizRating = 0
    let passwordRating = 0


    users.forEach(user => {

        if (parseInt(t.password) > 2) {
            user.passwordRating = 10
        } else {
            user.passwordRating = 0
        }

        //I KNOW; THIS LOOKS LIKE SHIT; BUT I TRIED MULTIPLE THINGS; THIS WAS THE ONLY THING WORKING FOR SOME REEASON, inline operations didn*t do the trick there
        //checks if entity is existing, if not, creates it and adds 0
        if (!user.firstRating) {
            user.firstRating = 0
        }
        if (!user.secondRating) {
            user.secondRating = 0
        }
        if (!user.thirdRating) {
            user.thirdRating = 0
        }
        if (!user.optionOne) {
            user.optionOne = 0
        }
        if (!user.optionTwo) {
            user.optionTwo = 0
        }
        if (!user.optionThree) {
            user.optionThree = 0
        }

        if (user.done) {
            firstRating += user.firstRating
            secondRating += user.secondRating
            thirdRating += user.thirdRating

            let optionOne = user.optionOne
            let optionTwo = user.optionTwo
            let optionThree = user.optionThree


            user.quizRating = calcOptions(optionOne, optionTwo, optionThree)


            quizRating += user.quizRating
            passwordRating += user.passwordRating

            countDone++


        }

        if (!user.quizRating) {
            user.quizRating = 0
        }

        let {total, average} = calcUserScore(user)

        user.total = total
        user.average = average

    })

    let firstRoundAverage = round(firstRating > 0 ? firstRating / countDone : 0)


    let secondRoundAverage = round(secondRating > 0 ? secondRating / countDone : 0)


    let thirdRoundAverage = round(thirdRating > 0 ? thirdRating / countDone : 0)


    let quizAverage = round(quizRating > 0 ? quizRating / countDone : 0)


    let teamTotal = firstRating + secondRating + thirdRating + quizRating + passwordRating


    let teamTotalAverage = (firstRating + secondRating + thirdRating + quizRating + passwordRating) / countDone

    if (isNaN(teamTotalAverage)) {
        teamTotalAverage = 0;
    } else {
        teamTotalAverage = Math.round((teamTotalAverage + Number.EPSILON))
    }

    let result = {
        ...t,
        firstRoundAverage,
        secondRoundAverage,
        thirdRoundAverage,
        quizAverage,
        teamTotal,
        teamTotalAverage,
        users
    }


    return result


}

