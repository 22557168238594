import React from "react"
import styled from "styled-components"


const Button = ({handleClick, text}) => {
    return (
        <StyledButton onClick={() => handleClick ? handleClick() : null}>
            {text}
        </StyledButton>
    )
}


const StyledButton = styled.button`
  margin: 120px auto;
  cursor: pointer;
  color: white;
  background-color: black;
  border-radius: 4px;
  border: 2px solid white;
  padding: 24px 18px;
  font: unset;
  font-weight: 400;
  font-size: 1.5rem;
`

export default Button