import React from "react"
import {OrganizationProvider} from "./OrganizationContext";
import {LoadingProvider} from "./LoadingConext";
import {RoomProvider} from "./RoomContext";
import {OverviewProvider} from "./OverviewContext";

const Context = (props) => {

    return (
        <LoadingProvider>
            <OverviewProvider>
                <OrganizationProvider>
                    <RoomProvider>
                        {props.children}
                    </RoomProvider>
                </OrganizationProvider>
            </OverviewProvider>
        </LoadingProvider>
    )
}

export default Context



