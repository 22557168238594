import React from "react";
import ReactPlayer from "react-player";

const Player = ({url, onEnded, onStart, onProgress, playing = true}) => {
    const handleOnStart = () => {
        if (onStart) {
            onStart()
        } else {

        }
    };
    const handleOnEnded = () => {
        if (onEnded) {
            onEnded()
        } else {

        }

    };
    const handleOnProgress = (progress) => {
        if (onProgress) {
            onProgress(progress)
        } else {

        }
    };

    if (url) {
        return (
            <ReactPlayer
                playing={playing}
                onProgress={(progress) => handleOnProgress(progress)}
                onEnded={() => handleOnEnded()}
                height={"100vh"}
                width={"100vw"}
                style={{backgroundColor: "black"}}
                onStart={() => handleOnStart()}
                url={url}
                config={{
                    vimeo: {
                        playerOptions: {
                            autoplay: true,
                            title: false,
                            playsinline: true,
                            quality: "540p",
                            texttrack: "nl"
                        }
                    }
                }}
            />
        );
    } else {
        return (
            <div></div>
        )
    }

};

export default Player;
