export function sort(entity, direction, a, b) {

    switch (entity) {
        case "text":
            return direction === "asc" ? sortTextAsc(a, b) : sortTextDesc(a, b)
        case "number":
            return direction === "asc" ? sortNumberAsc(a, b) : sortNumberDesc(a, b)
    }

}


const sortTextAsc = (textA, textB) => {

    if (textA && textB) {
        textA.toUpperCase()
        textB.toUpperCase()
        if (textA < textB) {
            return -1;
        }
        if (textA > textB) {
            return 1;
        }
    }
    // text muss gleich sein
    return 0;
}

const sortTextDesc = (textA, textB) => {
    if (textA && textB) {
        textA.toUpperCase()
        textB.toUpperCase()
        if (textA > textB) {
            return -1;
        }
        if (textA < textB) {
            return 1;
        }
    }
    // text muss gleich sein
    return 0;
}

const sortNumberDesc = (numberA, numberB) => {
    return numberA - numberB;
}
const sortNumberAsc = (numberA, numberB) => {
    return numberB - numberA;
}
