import React, {useMemo, useState} from "react"


export const OverviewContext = React.createContext()


export const OverviewProvider = (props) => {

    //state for session context
    const [overviewData, setOverviewData] = useState({})

//Function to change whatever is saved in the session context
    const changeOverviewData = (data) => {
        setOverviewData(overviewData => {
            return {
                ...overviewData,
                ...data
            }
        })
    }


//use memo to prevent unnecessary re-rendering
    let overviewValue = useMemo(() => ({
        overviewData,
        changeOverviewData
    }), [overviewData, changeOverviewData])


    return (
        <OverviewContext.Provider value={overviewValue}>
            {props.children}
        </OverviewContext.Provider>
    )
}