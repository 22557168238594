import React, {useEffect, useState, useRef} from "react";
import styled from "styled-components";

const Timer = ({timeInSeconds, setInterID, onProgress}) => {
    const [started, setStarted] = useState(false);
    const [progressWidth, setProgressWidth] = useState(-1);
    const [intervalID, setIntervalID] = useState(0);

    const barRef = useRef(null);

    useEffect(() => {
        if (!started && barRef?.current?.clientWidth) {
            setStarted(true);
            let adaptedTimeInSeconds = timeInSeconds * 10;

            let timeleft = adaptedTimeInSeconds;
            let timetotal = adaptedTimeInSeconds;

            let interval = setInterval(() => {
                if (barRef && barRef.current && barRef.current.clientWidth) {
                    let progressBarWidth =
                        (timeleft * barRef?.current?.clientWidth) / timetotal;
                    setProgressWidth(progressBarWidth);
                    timeleft = timeleft - 1;
                    if (timeleft === 0) {
                        clearInterval(interval);
                        setProgressWidth(0);
                    }

                    let timePassedInSeconds = ((timeleft - timetotal) / 10)
                    if (timePassedInSeconds % 1 === 0) {
                        onProgress(Math.abs(timePassedInSeconds))
                        console.log("TIMER ON")
                    }
                }
            }, 100);

            setIntervalID(interval);
            setInterID(interval);
        }

    }, [barRef, started, timeInSeconds, setInterID]);

    useEffect(() => {
        return () => clearInterval(intervalID)
    }, [])

    return (
        <Wrapper>
            <Timebar ref={barRef}>
                <Bar width={progressWidth}/>
            </Timebar>
        </Wrapper>
    );
};

const Bar = styled.div.attrs((props) => ({
    style: {
        width: props.width === -1 ? "100%" : `${props.width}px`
    }
}))`
  height: 100%;
  text-align: right;
  line-height: 22px; /* same as #progressBar height if we want text middle aligned */
  background-color: white;
  box-sizing: border-box;
`;

const Timebar = styled.div`
  width: 100%;
  margin: 10px auto;
  height: 22px;
  background-color: black;
`;

const Wrapper = styled.div``;

export default Timer;
