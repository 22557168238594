import React, {useEffect, useState, useContext} from 'react';
import {useHistory} from "react-router-dom";
import {OverviewContext} from "../../context/OverviewContext";
import {getTeams, hideTeam, showTeam} from "../../axios/index"
import {toast} from "react-toastify";
import styled from "styled-components"
import JoinWrapper from "../atoms/JoinWrapper";
import {sort} from "../../utils/sort"
import {calculateScore} from "../../utils/scorer";
import {ReactComponent as Arrow} from "../../public/icons/arrow-down.svg"
import {ReactComponent as Hidden} from "../../public/icons/hidden.svg"
import {ReactComponent as Visible} from "../../public/icons/visible.svg"
import day from "dayjs"

const Overview = (props) => {

    let {overviewData, changeOverviewData} = useContext(OverviewContext)
    let history = useHistory()
    const [teams, setTeams] = useState(null)
    const [showHidden, setShowHidden] = useState(false)
    const [currentSort, setCurrentSort] = useState("name_asc")


    const fetchJobs = () => {
        getTeams(overviewData.token).then((res) => {
            let teams = []
            res.teams.forEach(team => {
                let result = calculateScore(team)
                teams.push(result)
            })
            setTeams(teams)
        }).catch(error => {
            if (error?.response?.status) {
                toast.error("Geen huidige sessie, gelieve in te loggen")
                history.push("/team/login")
            }
        })
    }


    const handleSetHidden = (teamID) => {
        hideTeam(overviewData.token, teamID).then(() => {
            fetchJobs()
        })
    }

    const handleSetVisible = (teamID) => {
        showTeam(overviewData.token, teamID).then(() => {
            fetchJobs()
        })
    }

    useEffect(() => {
        if (overviewData) {
            fetchJobs()
        }
    }, [overviewData])

    const handleSortClick = (entity) => {

        switch (entity) {
            case "name":
                setCurrentSort(currentSort => currentSort === "name_asc" ? "name_desc" : "name_asc")
                break;
            case "first":
                setCurrentSort(currentSort => currentSort === "first_asc" ? "first_desc" : "first_asc")
                break;
            case "second":
                setCurrentSort(currentSort => currentSort === "second_asc" ? "second_desc" : "second_asc")
                break;
            case "third":
                setCurrentSort(currentSort => currentSort === "third_asc" ? "third_desc" : "third_asc")
                break;
            case "quiz":
                setCurrentSort(currentSort => currentSort === "quiz_asc" ? "quiz_desc" : "quiz_asc")
                break;
            case "total":
                setCurrentSort(currentSort => currentSort === "total_asc" ? "total_desc" : "total_asc")
                break;
            case "date":
                setCurrentSort(currentSort => currentSort === "date_asc" ? "date_desc" : "date_asc")
                break;
        }
    }

    const getSortedItems = () => {

        let teamsArray = teams.sort((a, b) => {
            switch (currentSort) {
                case "name_asc":
                    return sort("text", "asc", a.name, b.name)
                case "name_desc":
                    return sort("text", "desc", a.name, b.name)

                case "first_asc":
                    return sort("number", "asc", a.firstRoundAverage, b.firstRoundAverage)
                case "first_desc":
                    return sort("number", "desc", a.firstRoundAverage, b.firstRoundAverage)

                case "second_asc":
                    return sort("number", "asc", a.secondRoundAverage, b.secondRoundAverage)
                case "second_desc":
                    return sort("number", "desc", a.secondRoundAverage, b.secondRoundAverage)

                case "third_asc":
                    return sort("number", "asc", a.thirdRoundAverage, b.thirdRoundAverage)
                case "third_desc":
                    return sort("number", "desc", a.thirdRoundAverage, b.thirdRoundAverage)

                case "quiz_asc":
                    return sort("number", "asc", a.quizAverage, b.quizAverage)
                case "quiz_desc":
                    return sort("number", "desc", a.quizAverage, b.quizAverage)

                case "total_asc":
                    return sort("number", "asc", a.teamTotalAverage, b.teamTotalAverage)
                case "total_desc":
                    return sort("number", "desc", a.teamTotalAverage, b.teamTotalAverage)

                case "date_asc":
                    return sort("number", "asc", new Date(a.createdAt), new Date(b.createdAt))
                case "date_desc":
                    return sort("number", "desc", new Date(a.createdAt), new Date(b.createdAt))

            }
        })


        return (
            teamsArray?.map(team => {
                if (team.hidden && showHidden) {
                    return (
                        <TableBodyWrapper key={team._id}>
                            <TableBodyData><TableBodyDataText>{team.name}</TableBodyDataText></TableBodyData>
                            <TableBodyData><TableBodyDataText>{team.firstRoundAverage}</TableBodyDataText></TableBodyData>
                            <TableBodyData><TableBodyDataText>{team.secondRoundAverage}</TableBodyDataText></TableBodyData>
                            <TableBodyData><TableBodyDataText>{team.thirdRoundAverage}</TableBodyDataText></TableBodyData>
                            <TableBodyData><TableBodyDataText>{team.quizAverage}</TableBodyDataText></TableBodyData>
                            <TableBodyData><TableBodyDataText>{team.teamTotalAverage}</TableBodyDataText></TableBodyData>
                            <TableBodyData><TableBodyDataText>{day(team.createdAt).format("DD.MM.YY")}</TableBodyDataText></TableBodyData>
                            <TableBodyData><IconWrapper
                                onClick={() => handleSetVisible(team._id)}><Hidden/></IconWrapper></TableBodyData>
                        </TableBodyWrapper>
                    )
                }

                if (!team.hidden) {
                    return (
                        <TableBodyWrapper key={team._id}>
                            <TableBodyData><TableBodyDataText>{team.name}</TableBodyDataText></TableBodyData>
                            <TableBodyData><TableBodyDataText>{team.firstRoundAverage}</TableBodyDataText></TableBodyData>
                            <TableBodyData><TableBodyDataText>{team.secondRoundAverage}</TableBodyDataText></TableBodyData>
                            <TableBodyData><TableBodyDataText>{team.thirdRoundAverage}</TableBodyDataText></TableBodyData>
                            <TableBodyData><TableBodyDataText>{team.quizAverage}</TableBodyDataText></TableBodyData>
                            <TableBodyData><TableBodyDataText>{team.teamTotalAverage}</TableBodyDataText></TableBodyData>
                            <TableBodyData><TableBodyDataText>{day(team.createdAt).format("DD.MM.YY")}</TableBodyDataText></TableBodyData>
                            <TableBodyData><IconWrapper
                                onClick={() => handleSetHidden(team._id)}><Visible/></IconWrapper></TableBodyData>
                        </TableBodyWrapper>

                    )
                }
            })
        )
    }


    return (
        <JoinWrapper>
            <TableGridWrapper>
                <TeamOverviewButton onClick={() => history.push("/")}>Terug</TeamOverviewButton>
                <OrgNameWrapper>
                    <OrgNameHeader>
                        {overviewData.orgName}
                    </OrgNameHeader>
                </OrgNameWrapper>
                <InfoWrapper>
                    <InfoHeader>Team overzicht</InfoHeader>
                    <UpdateButton onClick={() => fetchJobs()}>updaten</UpdateButton>
                </InfoWrapper>

                <ShowHiddenWrapper>
                    <HiddenText>Geef verborgen weer</HiddenText>
                    <CheckBox onChange={(e) => setShowHidden(e.target.checked)} type="checkbox"></CheckBox>
                </ShowHiddenWrapper>
                <TableWrapper>
                    <TableHeaderWrapper>
                        <TableHeaderData onClick={() => handleSortClick("name")}>
                            <TableHeaderDataText>Name</TableHeaderDataText>
                            <IconWrapper asc={currentSort === "name_asc"}> <Arrow></Arrow></IconWrapper>
                        </TableHeaderData>

                        <TableHeaderData onClick={() => handleSortClick("first")}>
                            <TableHeaderDataText>Gemiddelde 1e hack</TableHeaderDataText>
                            <IconWrapper asc={currentSort === "first_asc"}> <Arrow></Arrow></IconWrapper>
                        </TableHeaderData>
                        <TableHeaderData onClick={() => handleSortClick("second")}>
                            <TableHeaderDataText>Gemiddelde 2e hack</TableHeaderDataText>
                            <IconWrapper asc={currentSort === "second_asc"}> <Arrow></Arrow></IconWrapper>
                        </TableHeaderData>
                        <TableHeaderData onClick={() => handleSortClick("third")}>
                            <TableHeaderDataText>Gemiddelde 3e hack</TableHeaderDataText>
                            <IconWrapper asc={currentSort === "third_asc"}> <Arrow></Arrow></IconWrapper>
                        </TableHeaderData>
                        <TableHeaderData onClick={() => handleSortClick("quiz")}>
                            <TableHeaderDataText>Spektakelstuk gemiddelde</TableHeaderDataText>
                            <IconWrapper asc={currentSort === "quiz_asc"}> <Arrow></Arrow></IconWrapper>
                        </TableHeaderData>
                        <TableHeaderData onClick={() => handleSortClick("total")}>
                            <TableHeaderDataText>Gemiddelde eindscore team</TableHeaderDataText>
                            <IconWrapper asc={currentSort === "total_asc"}> <Arrow></Arrow></IconWrapper>
                        </TableHeaderData>
                        <TableHeaderData onClick={() => handleSortClick("date")}>
                            <TableHeaderDataText>Datum</TableHeaderDataText>
                            <IconWrapper asc={currentSort === "date_asc"}> <Arrow></Arrow></IconWrapper>
                        </TableHeaderData>
                        <TableHeaderData>
                            <TableHeaderDataText>Verberg</TableHeaderDataText>
                            <IconWrapper asc={currentSort === "date_asc"}> <Arrow></Arrow></IconWrapper>
                        </TableHeaderData>
                    </TableHeaderWrapper>
                    {teams && getSortedItems()}
                </TableWrapper>

            </TableGridWrapper>
        </JoinWrapper>
    );
}


const TeamOverviewButton = styled.button`

  position: absolute;
  top: 40px;
  left: 40px;
  cursor: pointer;
  font-size: 1.2rem;

  color: white;
  background-color: black;
  border-radius: 4px;
  border: 2px solid white;
  padding: 8px 12px;
  font-weight: 400;
`


const HiddenText = styled.p`
  color: white;
  font-size: 1rem;
  margin-right: 4px;
`

const CheckBox = styled.input`

`


const ShowHiddenWrapper = styled.div`
  grid-row: 3/4;
  grid-column: 9/12;
  display: flex;
  justify-content: center;
  align-items: center;

`

const TableHeaderDataText = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #dddddd;

`

const TableBodyDataText = styled(TableHeaderDataText)`
  font-weight: 400;
  color: black;
`


const IconWrapper = styled.div`
  transform: ${props => !props.asc && "rotate(.5turn)"};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;


  & svg {
    height: 28px;
    width: 28px;
    fill: #dddddd;;

  }
`
const TableHeaderData = styled.div`
  min-width: 170px;

  width: calc(100% / 8);
  height: 60px;
  background-color: #4e4e4e;
  display: flex;
  align-items: center;
  padding: 0 24px;
  box-sizing: border-box;
  cursor: pointer;

`

const TableBodyData = styled(TableHeaderData)`
  background-color: transparent;
  cursor: initial;
  
`

const TableHeaderWrapper = styled.div`
  display: flex;


`

const TableBodyWrapper = styled(TableHeaderWrapper)`
  &:nth-child(odd) {
    ${TableHeaderData} {
      background: white;
    }
  }

  &:nth-child(even) {
    ${TableHeaderData} {
      background: lightgrey;
    }
  }

`

const TableWrapper = styled.div`
  background-color: black;
  border: 2px solid white;
  border-radius: 4px;
  grid-row: 4/13;
  grid-column: 1/13;
  margin: 0 40px;
  overflow: auto;

  @media (max-width: 1366px) {

    margin: 0;
  }
`


const UpdateButton = styled.button`
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: 40px;
  color: white;
  background-color: black;
  border-radius: 4px;
  border: 2px solid white;
  padding: 8px 12px;
  font-weight: 400;

`
const OrgNameHeader = styled.h2`
  color: white;
  margin-left: 40px;
`
const OrgNameWrapper = styled.div`
  grid-column: 1/4;
  grid-row: 2/3;
  display: flex;
  align-items: center;
`

const InfoWrapper = styled(OrgNameWrapper)`
  grid-column: 1/8;
  grid-row: 3/4;
`

const InfoHeader = styled(OrgNameHeader)`


`

const TableGridWrapper = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
`


export default Overview;