import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components'
import GameWrapper from "../../atoms/GameWrapper";
import TeamHeader from "../../molecules/TeamHeader"
import {RoomContext} from "../../../context/RoomContext";
import Timer from "../../molecules/TimeBar";
import Button from "../../atoms/Button";


const VotingWaitingRoom = ({round, syncPageSwitch}) => {


    let secondsToWait = 30
    let rating = round === 1 ? "firstRating" : round === 2 ? "secondRating" : "thirdRating"


    const [interID, setInterID] = useState(null)
    const [usersDone, setUsersDone] = useState(0)


    let {roomData} = useContext(RoomContext)


    useEffect(() => {

        if (roomData.users) {
            let counter = 0
            roomData.users.map(user => {
                if (user[rating]) {
                    counter++
                }
            })

            setUsersDone(counter)
        }

    }, [roomData])

    useEffect(() => {

        return () => interID && clearInterval(interID)
    }, [])


    const startNextRound = () => {
        syncPageSwitch()
    }
    
    const handleProgress = sec => {
        console.log(sec)
        if (sec >= secondsToWait) {
            startNextRound()
        }
    }


    const getUsersNotDone = () => {
        return roomData?.users?.map(user => {
            if (!user[rating]) {
                return (
                    <PlayerName key={user.userID}>{user.userName}</PlayerName>
                )
            }
        })
    }


    const getUsersDone = () => {
        return roomData?.users?.map(user => {
            if (user[rating]) {
                return (
                    <PlayerName key={user.userID}>{user.userName}</PlayerName>
                )
            }
        })
    }


    return (

        <InnerGameWrapper>

            <GridWrapper>
                <TeamHeaderWrapper>
                    <TeamHeader/>
                </TeamHeaderWrapper>
                <HeaderWrapper>
                    <Header>Wacht tot iedereen een score heeft doorgegeven</Header>
                    <SubHeader>Iedereen die zijn score al heeft doorgegeven:</SubHeader>
                </HeaderWrapper>
                <WaitingForPlayerWrapper>
                    <SubHeader>Nog niet gedaan</SubHeader>
                    {getUsersNotDone()}
                </WaitingForPlayerWrapper>
                <DonePlayerWrapper>
                    <SubHeader>Gedaan</SubHeader>
                    {getUsersDone()}
                </DonePlayerWrapper>
                <FooterWrapper>
                    <ContinueButtonWrapper timerOn={usersDone === roomData?.users?.length}>

                        <Button text={"Iedereen zijn score doorgegeven? Ga door!"}
                                handleClick={() => startNextRound()}/>
                    </ContinueButtonWrapper>
                </FooterWrapper>

                <TimerWrapper>
                    {usersDone === roomData?.users?.length && (
                        <div>
                            <Timer setInterID={id => setInterID(id)} timeInSeconds={secondsToWait}
                                   onProgress={sec => handleProgress(sec)}/>
                        </div>
                    )}
                </TimerWrapper>

            </GridWrapper>
        </InnerGameWrapper>

    );
    
}


const ContinueButtonWrapper = styled.div`

  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);


  & button {
    @media (max-height: 760px) {
      font-size: 16px;
      transition: margin 700ms ease-in-out;
      ${props => props.timerOn && "margin: 250px auto 0px"}

    }

    margin: 0;
  }

`

const PlayerName = styled.p`

`

const SubHeader = styled.h2`
  text-align: center;
  @media (max-height: 760px) {
    font-size: 18px;
  }
  

`

const Header = styled.h1`
  max-width: 60%;
  margin: 24px auto;
  @media (max-height: 760px) {
    font-size: 22px;
  }

`


const FooterWrapper = styled.div`
  grid-column: 1/3;
  grid-row: 4/5;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

const HeaderWrapper = styled.div`
  grid-column: 1/3;
  grid-row: 2/3;
  margin: 40px 0;
  text-align: center;
`

const WaitingForPlayerWrapper = styled.div`
  grid-column: 1/2;
  grid-row: 3/4;
  text-align: center;
`

const TeamHeaderWrapper = styled.div`
  grid-column: 1/3;
  grid-row: 1/2;
`

const TimerWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`

const DonePlayerWrapper = styled(WaitingForPlayerWrapper)`
  grid-column: 2/3;
  text-align: center;

`

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows:min-content min-content 2fr 1fr 1fr;

  box-sizing: border-box;
  color: white;
  overflow: auto;
  height: 100%;

`


const InnerGameWrapper = styled(GameWrapper)`
  display: flex;
  justify-content: center;

`

export default VotingWaitingRoom;