import React, {useMemo, useState} from "react"


export const OrganizationContext = React.createContext()

export const OrganizationProvider = (props) => {

    //state for session context
    const [organizationData, setOrganizationData] = useState({})


//Function to change whatever is saved in the session context
    const changeOrganizationData = (data) => {
        setOrganizationData(organizationData => {
            return {
                ...organizationData,
                ...data
            }
        })
    }


//use memo to prevent unnecessary re-rendering
    let organizationValue = useMemo(() => ({
        organizationData,
        changeOrganizationData


    }), [organizationData, changeOrganizationData])
    

    return (
        <OrganizationContext.Provider value={organizationValue}>
            {props.children}
        </OrganizationContext.Provider>
    )
}