import React, {useContext, useState, useEffect} from 'react';
import styled from "styled-components"
import Player from "../../molecules/Player";
import {RoomContext} from "../../../context/RoomContext";
import {OrganizationContext} from "../../../context/OrganizationContext";


const PreDiscussionPage = ({round, forwardPage}) => {

    let {roomData} = useContext(RoomContext)
    let {organizationData} = useContext(OrganizationContext)


    let {
        //First rounds data
        speakerFeedbackFirstAssignment,
        speakerFirstFeedbackScoringDelay,
        speakerBulletPoints1Assignment1,
        speakerBulletPoints2Assignment1,
        speakerBulletPoints3Assignment1,

        //Second rounds data
        speakerFeedbackSecondAssignment,
        speakerSecondFeedbackScoringDelay,
        speakerBulletPoints1Assignment2,
        speakerBulletPoints2Assignment2,
        speakerBulletPoints3Assignment2,

        //Third rounds data
        speakerFeedbackThirdAssignment,
        speakerThirdFeedbackScoringDelay,
        speakerBulletPoints1Assignment3,
        speakerBulletPoints2Assignment3,
        speakerBulletPoints3Assignment3


    } = organizationData

    // holds video url
    let [videoURL, setVideoURL] = useState("")

    // state for holding how many seconds should be waited until showing the score
    let [waitSeconds, setWaitSeconds] = useState(-1)

    //state for showing results
    let [showResult, setShowResult] = useState(false)

    //state for array of the bulletpoints
    let [bulletPoints, setBulletPoints] = useState([])

    //state for holding the player name with the highest score
    let [bestPlayerName, setBestPlayerName] = useState("")


    useEffect(() => {

        console.log(videoURL)


        //setting the bullet points depending on current round
        if (videoURL.length <= 0 && roomData.users?.length > 0) {
            switch (round) {
                case 1:

                    setVideoURL(speakerFeedbackFirstAssignment)
                    setWaitSeconds(speakerFirstFeedbackScoringDelay)
                    setBulletPoints([
                        speakerBulletPoints1Assignment1,
                        speakerBulletPoints2Assignment1,
                        speakerBulletPoints3Assignment1
                    ])

                    //sorts players based on last rating, sorts them and saves it in state
                    let orderedByPoints1 = roomData.users.sort((a, b) => {
                        return a.firstRating + b.firstRating
                    })

                    setBestPlayerName(orderedByPoints1[0].userName)

                    break;
                case 2:
                    setVideoURL(speakerFeedbackSecondAssignment)
                    setWaitSeconds(speakerSecondFeedbackScoringDelay)
                    setBulletPoints([
                        speakerBulletPoints1Assignment2,
                        speakerBulletPoints2Assignment2,
                        speakerBulletPoints3Assignment2
                    ])
                    //sorts players based on last rating, sorts them and saves it in state
                    let orderedByPoints2 = roomData.users.sort((a, b) => {
                        return a.secondRating + b.secondRating
                    })

                    setBestPlayerName(orderedByPoints2[0].userName)
                    break;
                case 3:
                    setVideoURL(speakerFeedbackThirdAssignment)
                    setWaitSeconds(speakerThirdFeedbackScoringDelay)
                    setBulletPoints([
                        speakerBulletPoints1Assignment3,
                        speakerBulletPoints2Assignment3,
                        speakerBulletPoints3Assignment3
                    ])
                    //sorts players based on last rating, sorts them and saves it in state
                    let orderedByPoints3 = roomData.users.sort((a, b) => {
                        return a.thirdRating + b.thirdRating
                    })

                    setBestPlayerName(orderedByPoints3[0].userName)
                    break;
            }


        }

    }, [round, organizationData, roomData])

    //gets video progress
    const handleProgress = (progress) => {

        //handles showing the ranking and bullet points after the seconds set to wait for
        let playedSeconds = Math.round(progress.playedSeconds)
        if (playedSeconds >= waitSeconds && !showResult) {
            setShowResult(true)
        }
    }


    return (
        <Wrapper>
            <OverlayWrapper showing={showResult}>
                <RatingHeader>
                    Mijn beoordeling:
                </RatingHeader>
                {bulletPoints.map((bp, i) => {
                    return (
                        <BulletPoint key={i}>{bp}</BulletPoint>
                    )
                })}
                <Divider/>
                <BulletPoint>{bestPlayerName} heeft de hoogste score!</BulletPoint>

            </OverlayWrapper>
            <Player
                onProgress={(prog) => handleProgress(prog)}
                onEnded={() => forwardPage()}
                url={videoURL}/>
        </Wrapper>
    );
}


export default PreDiscussionPage;


const Divider = styled.div`
  height: 2px;
  width: 80%;
  background-color: rgba(255, 255, 255, 0.4);
  margin: 20px 0;
`

const RatingHeader = styled.h2`
  text-shadow: -2px 2px 4px #000;
  color: white;
  font-size: 2.2rem;
`
const BulletPoint = styled.p`
  color: white;
  font-weight: 400;
  font-size: 1.4rem;
  text-shadow: -2px 2px 4px #000;
  margin: 4px 0;

`

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`

const OverlayWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  right: ${props => props.showing ? "40px" : "-100%"};
  display: flex;
  flex-direction: column;
  justify-content: center;

  transition: right ease-in-out .8s;
  height: 400px;
  width: 400px;

`