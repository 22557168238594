import React, {useContext, useEffect, useState} from 'react';
import {RoomContext} from "../../../context/RoomContext";
import Player from "../../molecules/Player";
import {OrganizationContext} from "../../../context/OrganizationContext";
import HackerPage from "../Pages/HackerPage"
import Voting from "../Pages/VotingPage"
import VotingWaitingRoom from "./VotingWaitingRoom";
import DiscussionPage from "./DiscussionPage"
import PreDiscussionPage from "./PreDiscussionPage";
import SpeedVoting from "./SpeedVoting";
import EndPage from "./EndPage";

//TODO MAYE UPDATE USER DATA ON EVERY PAGE SWITCH FOR EVERYBODY; SO ALL ARE UP TO DATE ALL THE TIME


const Game = (props) => {

    let {roomData, changeRoomData, nextPage} = useContext(RoomContext)
    let {organizationData, changeOrganizationData} = useContext(OrganizationContext)
    let [optionsScore, setOptionsScore] = useState(null)

    let currentPage = roomData.currentPage


    useEffect(() => {

        if (currentPage === 30) {

            if (roomData.done) {
                let score = (!isNaN(roomData.optionOne) && roomData.optionOne) + (!isNaN(roomData.optionTwo) && roomData.optionTwo + (!isNaN(roomData.optionThree) && roomData.optionThree))
                setOptionsScore(score)
                return;
            }

            if (!roomData.optionOne && !roomData.optionTwo && !roomData.optionThree) {
                setOptionsScore(0)
                return
            }

        }

    }, [currentPage, roomData])


    const onProgress = (progress) => {
        // console.log(progress)
    }

    const onStart = () => {

    }
    const forwardPage = () => {
        props.handleNextPage(roomData.userID, roomData.currentPage + 1)
        nextPage()
    }

    /************FIRST ROUND***************/

    //Video introduction of the hackers
    if (currentPage === 1) {
        return <Player
            key={1}
            onProgress={onProgress}
            onStart={onStart}
            onEnded={forwardPage}
            url={organizationData.intoVideo}
        />
    }
    //Organisation hello video depending on password score
    if (currentPage === 2) {
        return <Player
            key={2}
            onProgress={onProgress}
            onStart={onStart}
            onEnded={forwardPage}
            url={roomData.teamPassword > 2 ? organizationData.videoGoodPassword : organizationData.videoBadPassword}/>
    }
    //Introduction to the first assignment
    if (currentPage === 3) {
        return <Player
            key={3}
            onProgress={onProgress}
            onStart={onStart}
            onEnded={forwardPage}
            url={organizationData.videoFirstAssignment}/>
    }
    //First assignment HackerPage with videos
    if (currentPage === 4) {
        return <HackerPage forwardPage={forwardPage} round={1}/>
    }
    //Info Video after watching first hacker round
    if (currentPage === 5) {
        return <Player
            key={5}
            onProgress={onProgress}
            onStart={onStart}
            onEnded={forwardPage}
            url={organizationData.videoFirstVoting}/>
    }


    //first voting
    if (currentPage === 6) {
        return <Voting
            forwardPage={forwardPage}
            voteComplete={(votingRound, result) => props.handleVoteComplete(votingRound, result)}
            round={1}/>
    }

    //Waiting room after voting
    if (currentPage === 7) {
        return <VotingWaitingRoom syncPageSwitch={() => props.handleSyncPageSwitch(8)} round={1}/>
    }


    //speaker Feedback
    if (currentPage === 8) {
        return <PreDiscussionPage forwardPage={forwardPage} round={1}/>
    }

    //video before first discussion
    if (currentPage === 9) {
        return <Player
            key={9}
            onProgress={onProgress}
            onStart={onStart}
            onEnded={forwardPage}
            url={organizationData.videoDiscussionFirstAssignment}/>
    }


    //first discussion
    if (currentPage === 10) {
        return <DiscussionPage syncPageSwitch={() => props.handleSyncPageSwitch(11)} round={1}/>
    }

    /************SECOND ROUND***************/

    //Introduction to the first assignment
    if (currentPage === 11) {
        return <Player
            key={11}
            onProgress={onProgress}
            onStart={onStart}
            onEnded={forwardPage}
            url={organizationData.videoSecondAssignment}/>
    }
    //Second assignment HackerPage with videos
    if (currentPage === 12) {
        return <HackerPage forwardPage={forwardPage} round={2}/>
    }

    //Info Video after watching second hacker round
    if (currentPage === 13) {
        return <Player
            key={13}
            onProgress={onProgress}
            onStart={onStart}
            onEnded={forwardPage}
            url={organizationData.videoSecondVoting}/>
    }

    //second voting
    if (currentPage === 14) {
        return <Voting
            forwardPage={forwardPage}
            voteComplete={(votingRound, result) => props.handleVoteComplete(votingRound, result)}
            round={2}/>
    }

    //Waiting room after voting
    if (currentPage === 15) {
        return <VotingWaitingRoom syncPageSwitch={() => props.handleSyncPageSwitch(16)} round={2}/>
    }


    //speaker Feedback
    if (currentPage === 16) {
        return <PreDiscussionPage forwardPage={forwardPage} round={2}/>
    }

    //video before second discussion
    if (currentPage === 17) {
        return <Player
            key={17}
            onProgress={onProgress}
            onStart={onStart}
            onEnded={forwardPage}
            url={organizationData.videoDiscussionSecondAssignment}/>
    }

    //second discussion
    if (currentPage === 18) {
        return <DiscussionPage syncPageSwitch={() => props.handleSyncPageSwitch(19)} round={2}/>
    }

    /************THIRD ROUND***************/

    //Introduction to the third assignment
    if (currentPage === 19) {
        return <Player
            key={19}
            onProgress={onProgress}
            onStart={onStart}
            onEnded={forwardPage}
            url={organizationData.videoThirdAssignment}/>
    }
    //Third assignment HackerPage with videos
    if (currentPage === 20) {
        return <HackerPage forwardPage={forwardPage} round={3}/>
    }
    //Info Video after watching third hacker round
    if (currentPage === 21) {
        return <Player
            key={21}
            onProgress={onProgress}
            onStart={onStart}
            onEnded={forwardPage}
            url={organizationData.videoThirdVoting}/>
    }


    //third voting
    if (currentPage === 22) {
        return <Voting
            forwardPage={forwardPage}
            voteComplete={(votingRound, result) => props.handleVoteComplete(votingRound, result)}
            round={3}/>
    }

    //Waiting room after voting
    if (currentPage === 23) {
        return <VotingWaitingRoom syncPageSwitch={() => props.handleSyncPageSwitch(24)} round={3}/>
    }


    //speaker Feedback
    if (currentPage === 24) {
        return <PreDiscussionPage forwardPage={forwardPage} round={3}/>
    }

    //video before third discussion
    if (currentPage === 25) {
        return <Player
            key={25}
            onProgress={onProgress}
            onStart={onStart}
            onEnded={forwardPage}
            url={organizationData.videoDiscussionThirdAssignment}/>
    }

    //third discussion
    if (currentPage === 26) {
        return <DiscussionPage syncPageSwitch={() => props.handleSyncPageSwitch(27)} round={3}/>
    }

    //speedVoting 1
    if (currentPage === 27) {
        return <SpeedVoting key={1}
                            forwardPage={forwardPage}
                            voteComplete={(votingRound, result) => props.handleVoteComplete(votingRound, result)}
                            stopVideoAfter={477} showOptionsAfter={468} speedRound={1}/>
    }

    //speedVoting 2
    if (currentPage === 28) {
        return <SpeedVoting key={2}
                            forwardPage={forwardPage}
                            voteComplete={(votingRound, result) => props.handleVoteComplete(votingRound, result)}
                            stopVideoAfter={39} showOptionsAfter={3} speedRound={2}/>
    }
    //speedVoting 3
    if (currentPage === 29) {
        return <SpeedVoting key={3}
                            forwardPage={forwardPage}
                            voteComplete={(votingRound, result) => props.handleVoteComplete(votingRound, result)}
                            stopVideoAfter={27} showOptionsAfter={3} speedRound={3}/>
    }


    // END VIDEO
    if (currentPage === 30 && optionsScore >= 0) {
        let score = optionsScore

        if (score >= 0 && !isNaN(score)) {
            let url = ""
            if (score === 3) {
                url = organizationData.speakerThanksGoodSelection
                if (url.length > 0) {
                    return (
                        <Player
                            key={"30.1"}
                            onProgress={onProgress}
                            onStart={onStart}
                            onEnded={forwardPage}
                            url={url}/>
                    )
                }
            } else {
                url = organizationData.speakerThanksBadSelection
                if (url.length > 0) {
                    return (
                        <Player
                            key={"30.2"}
                            onProgress={onProgress}
                            onStart={onStart}
                            onEnded={forwardPage}
                            url={url}/>
                    )
                }
            }

        } else {
            return <p>Loading</p>
        }
    }


    // END VIDEO
    if (currentPage === 31) {
        return <Player
            key={31}
            onProgress={onProgress}
            onStart={onStart}
            onEnded={forwardPage}
            url={organizationData.organizationThankYouVideo}/>

    }
    // TEAM RANKING
    if (currentPage === 32) {
        return <EndPage getEndRating={() => props.getEndRating()}/>
    }


    return (
        <div>
            <p>No fitting currentPage found</p>
        </div>
    );
}
export default Game;
