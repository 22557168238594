import React, {useContext, useState} from 'react';
import {OrganizationContext} from "../../context/OrganizationContext";
import {RoomContext} from "../../context/RoomContext";
import JoinWrapper from "../atoms/JoinWrapper";
import styled from "styled-components"
import InputField from "../atoms/InputField";
import ArrowRight from "../../public/icons/arrow_right.svg"
import {useHistory} from "react-router-dom"
import {toast} from 'react-toastify';
import {LoadingContext} from "../../context/LoadingConext";
import {companyValidator} from "../../utils/companyValidator";
import {create_UUID} from "../../utils/createUUID";
import Button from "../atoms/Button"

const Landing = (props) => {
    let organizationContext = useContext(OrganizationContext)
    let loadingContext = useContext(LoadingContext)
    let roomContext = useContext(RoomContext)
    let history = useHistory()
    const [orgCode, setOrgCode] = useState("")
    const [ready, setReady] = useState(false)
    const handleCodeInput = (value) => {
        try {
            setOrgCode(value)

            if (value.length > 0) {
                setReady(true)
                return
            }
            setReady(false)
        } catch (e) {
            console.error(e)
        }
    }


    const handleForwardClick = (e) => {
        e?.preventDefault()
        if (orgCode.length > 0) {
            loadingContext.startLoading()
            roomContext.changeRoomData({
                orgCode: orgCode
            })
            companyValidator(orgCode, history).then(organizationData => {
                let roomCode = create_UUID()
                organizationContext.changeOrganizationData({...organizationData})
                roomContext.changeRoomData({roomCode: roomCode})
                loadingContext.stopLoading()
                toast.success("Organisatie gevonden")
                history.push(`/join/${orgCode}/${roomCode}`)
            }).catch(error => {
                loadingContext.stopLoading()
                console.error(error)
            })
        }
    }
    return (
        <JoinWrapper>
            <Wrapper>
                <TeamOverviewButton onClick={() => history.push("/team/login")}>Scorebord</TeamOverviewButton>
                <HeaderWrapper>
                    <SmallHeader>Heel Holland</SmallHeader>
                    <Header>Hackt</Header>
                </HeaderWrapper>
                <FieldWrapper onSubmit={(e) => handleForwardClick(e)}>
                    <InputField value={orgCode} textAlign={"center"} handleInput={value => handleCodeInput(value)}
                                placeholder={"Voer uw code in"}/>
                    <Button text={"Ga naar uw organisatie pagina"}/>
                </FieldWrapper>
            </Wrapper>
        </JoinWrapper>
    );
}


export default Landing;

const TeamOverviewButton = styled.button`

  position: absolute;
  top: 40px;
  left: 40px;
  cursor: pointer;
  font-size: 1.2rem;

  color: white;
  background-color: black;
  border-radius: 4px;
  border: 2px solid white;
  padding: 8px 12px;
  font-weight: 400;
`

const ContinueButton = styled.button`
  background: url(${ArrowRight}) ${props => props.ready ? "white" : "gray"} center no-repeat;
  background-size: 34px;
  padding: 32px;
  border: none;
  margin-left: 18px;
  border-radius: 50px;
  cursor: ${props => props.ready ? "pointer" : "default"};

  &:active {
    box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
  }
}
`

const FieldWrapper = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 118px;
  justify-content: center;
  align-items: center;

  & button {
    margin: 40px auto;
  }


`

const Header = styled.h1`
text-align: center;
color: white;
font-size: 120px;
margin: 0;
font-weight: 700;
text-transform: uppercase;
`
const SmallHeader = styled(Header)`
  font-size: 64px;
`


const HeaderWrapper = styled.div`

`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
`



